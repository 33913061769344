// main: guide_style.styl

var-font-size = 12px

a, button, input, select
	transition: all .5s ease-in-out

.btn2
	font-size var-font-size + 5
	padding: 10px
	width: 180px
	text-align: center
	border-radius: 500px

	&:after
		content: '';
		display: inline-block
		width: 25px
		height: 17px;
		background: url(https://asset.healthinsurancecomparison.com.au/images/icons/lefticon.png) no-repeat
		position: relative
		margin: 0 0 0 10px
		top: 2px

.color1
	background-color: #71be59
	border-bottom: 4px solid #67aa52
	color: white
	font-family inherit
	font-weight bold
	text-decoration: none !important

	&:hover
	&:focus
		background-color: #67aa52;
		color: white

/*inner section*/
#inner-page
	#sidebar-form
		width: 100%

	@media (min-width 1200px)
		padding: 7rem 8rem

		.article-content-wrapper
			padding-right: 40px

	@media (max-width 767px)
		padding: 4rem 2.5rem

	.inner-main-title
		font-size var-font-size + 19
		margin-bottom: 5px

	.inner-main-title:after
		background: linear-gradient(140deg, #1d8099 0%, #379eb3 50%, #4db6c9 75%)
		height: 6px
		display: block
		content: ""
		margin-top: 15px
		width: 175px

	h2
		font-size var-font-size + 20
		font-family inherit
		font-weight bold
		margin-top 0
		margin-bottom: 20px
		color #22849d

		@media (max-width 767px)
			font-size: 28px

	.container
		background-color: #fff
		padding: 70px 80px
		box-shadow 0px 0px 4px 0px rgba(70,70,70,0.1)

.inner-content-wrap
	padding-right: 40px
	.protection-title
		font-size: 24px
		font-family inherit
		font-weight bold
	p
		margin-bottom: 25px


/*archive section*/
#inner-page
	.main-blog-post-title
		color: #292929

		& > a
			color inherit
			font inherit
			text-decoration: none

	.main-blog-excerpt
		text-align: justify
		margin-bottom: 25px
		font-size: 16px

.main-blog-post
	padding: 30px 0
	border-bottom: 1px solid #e0e0e0

.main-blog-meta
	margin-bottom: 15px
	span
		color: #1b7f98
		font-size: 16px


.main-blog-post-date
	margin-right: 20px

.main-blog-post-date:before
.main-blog-post-author:before
	display: inline-block
	content: ""
	height: 20px
	width: 20px
	position: relative
	top: 5px
	margin-right: 5px

.main-blog-post-date:before
	background: url(https://asset.healthinsurancecomparison.com.au/images/post-calendar-icon.png) no-repeat
.main-blog-post-author:before
	background: url(https://asset.healthinsurancecomparison.com.au/images/post-author-icon.png) no-repeat

.main-blog-post img,
.blog-single-post img
	margin-bottom: 40px
	width: 100%

.main-blog-post .btn,
.blog-single-post .btn
	font-size var-font-size + 4

.blog-single-post
	h4, h6
		font-size var-font-size + 8
		font-family inherit
		font-weight bold

	h6
		font-size: 16px

	ol, ul
		margin: 0 0 40px
		padding: 0 0 0 40px

	ol
		padding: 0 0 0 55px
		li
			font-family inherit
			font-weight bold
			padding-left 5px


.main-blog-pagination
	list-style: none
	padding: 0
	margin: 30px auto 10px
	text-align: center
	vertical-align: top

	li
		display: inline-block
		line-height: 33px
		margin: 0 5px
		padding: 0 !important
		background: none !important
		vertical-align: top

		&.active
			a
				background: linear-gradient(140deg,#288ca4 0%,#3ea5ba 50%,#4bb4c6 75%)
				color: #fff
	a,
	.page-numbers
		display: block
		border-radius: 50%
		width: 34px
		height: 34px
		line-height: 32px
		text-align: center
		background: #fff
		color: #777
		font-weight: 700
		position: relative
		text-decoration:  none !important
		border 1px solid #46afc4
		transition: .3s ease-in-out


		&.current
			background: linear-gradient(140deg,#288ca4 0%,#3ea5ba 50%,#4bb4c6 75%)
			color: #fff

		&:hover,
		&:focus
			background: linear-gradient(140deg,#288ca4 0%,#3ea5ba 50%,#4bb4c6 75%)
			text-decoration: none
			color: #fff

		&.next
		&.prev
			background: #dedede
			border 0

			&:hover
			&:focus
				background: #f2f2f2

		&.next
			&:after
				content: ''
				width: 0;
				height: 0;
				border-top: 6px solid transparent
				border-bottom: 6px solid transparent
				border-left: 10px solid #71be59
				display: block
				position: absolute
				top: 50%
				left: 50%
				margin: -7px 0 0 -3px
				transition: .5s ease-in-out

		&.prev
			&:after
				content: ''
				width: 0;
				height: 0;
				border-top: 6px solid transparent
				border-bottom: 6px solid transparent
				border-right: 10px solid #71be59
				display: block
				position: absolute
				top: 50%
				left: auto
				right: 50%
				margin: -7px -3px 0 0
				transition: .5s ease-in-out

	.page-numbers
		&.dots
			background: none
			color: #777
			display: inline
			font-weight: normal
			border 0



/*sidebar section*/
.archive-box,
.popular-post-box-link
	padding: 20px 25px 20px 15px

.popular-post-box-link
	display: flex

	&:hover
	&:focus
		text-decoration: none

.popular-post,
.archive-post
	padding-top: 30px

.archive-post h4
	margin-top 0

.popular-post h4
.archive-post h4
	padding-bottom: 20px
	margin-bottom: 25px
	border-bottom: 2px solid #e8e8e8
	color: #22859d
	font-size var-font-size + 10
	font-weight: bold
	text-transform: uppercase

.popular-box,
.archive-box
	border: 1px solid #ebebeb
	margin-bottom: 15px
	border-radius 4px

.popular-box
	.popular-meta
		margin: 0
	img
		margin-top: 10px
		max-width: 55px
		height: 55px
		border-radius: 50%

.popular-post-box-link:hover
	background-color: #f2f2f2

.popular-img-post
	display: inline-block
	margin-right: 20px

.popular-meta
	.popular-post-date
		font-size var-font-size - 1
		color: #71be59
		font-weight: bold

.popular-single-post-content
	font-size var-font-size + 1
	line-height: 16px
	display: block
	color: #2d2d2d
	font-weight: bold

.archive-post
	ul
		li
			display: block
			position: relative
			margin-bottom: 15px

			a
				font-size var-font-size + 1
				color: #606060

				&:hover
					color: #3da4ba
					text-decoration: none

			&:before
				border-left: 8px solid #3da4ba
				border-top: 4px solid transparent
				border-bottom: 4px solid transparent
				font-size var-font-size - 12
				line-height: 0%
				width: 0px
				content: ""
				display: block
				position: absolute
				left: -20px
				top: 8px

			&:last-child
				margin: 0
