.about-content-wrapper .article-content .blog-wrapper .title-article h2
  color: #22849d
  font-family inherit
  font-weight 600

.about-content-wrapper .divide
  background: linear-gradient(to left, #5dc8d8, #11738e )
  height: 5px
  display: block
  margin: 12px 0px 25px 0px

.about-content-wrapper .article-content .blog-wrapper p
  font-size: 15px
  color: #343434
  margin: 0 0 15px
  line-height: 25px

.about-content-wrapper .article-content .blog-wrapper a
  font-family inherit
  font-weight 600
  text-decoration: underline

.about-content
  z-index: 1000
  position: relative
  background-color: #ffffff
  margin-top: -180px
  -webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)
  -moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)
  box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)
  min-height: 500px

.about-content-wrapper .article-content .blog-wrapper .ct-wrapper h3
  font-family inherit
  font-weight 600
  font-size: 20px

.about-content-wrapper .ct-wrapper
  display: block
  margin: 35px 0px

@media (min-width: 320px)
  .about-content-wrapper .divide
    width: 100%

  .about-content-wrapper .article-content
    padding: 1rem

@media (min-width: 480px)
  .about-content-wrapper .divide
    width: 100%

  .about-content-wrapper .article-content
    padding: 1rem

@media (min-width: 768px)
  .about-content-wrapper .divide
    width: 100px

  .about-content-wrapper .article-content
    padding: 2rem

@media (min-width: 992px)
  .about-content-wrapper .divide
    width: 100px

  .about-content-wrapper .article-content
    padding: 3rem

@media (min-width: 1200px)
  .about-content-wrapper .divide
    width: 100px

  .about-content-wrapper .article-content
    padding: 5rem 8rem

  .about-content-wrapper .article-content #content-bar .col-lg-9
	  width: 70%

  .about-content-wrapper .article-content #content-bar .col-lg-3
	  width: 30%
