// out: ../guide_style.min.css, sourcemap: true, compress: true, libs: nib
@import 'nib'
/* HIC Styles Code */

*
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;

body
	background-color: #f9f9f9
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;
	font-size: 15px
	color: #343434
	position: relative

	&.page-template-tpl-home-dynamic
		background: #fff

p
	font-size: 15px
	color: #343434
	margin: 0 0 15px

a
	color: #35899c

a:hover,
a:focus,
a:active
	color: #004e64

i
	color: #696969

strong
	font-weight 700

select.form-control
	border-width: 0px

.navbar
	min-height: 60px
	margin-bottom: 0px

.navbar-toggle
	margin-top: 13px
	margin-bottom: 13px
	margin-right: 0px

.navbar-light
	background-color: #ffffff
	border-color: #ffffff

.navbar-brand
	padding: 7px 0
	margin-top: 5px

nav.navbar
	-webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)
	-moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)
	box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)

.navbar-nav > li > a
	font-weight 600

.nav > li > a:focus,
.nav > li > a:hover
	background: #ffffff

.navbar-toggle .icon-bar
	background-color: #000

.navbar-toggle
	opacity: 0.5

.navbar-toggle:hover,
.navbar-toggle:focus
	opacity: 0.5

.content-wrapper
	margin: 0px
	padding: 0px

.top-space
	background: linear-gradient(to right, rgba(53,134,154,1) 0%,rgba(64,148,169,1) 100%)
	min-height: 250px
	z-index: 0

.article-content
	z-index: 1000
	position: relative
	background-color: #ffffff
	margin-top: -180px
	-webkit-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)
	-moz-box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)
	box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.05)
	min-height: 500px

.article-content .blog-wrapper a
	font-weight 600
	text-decoration: underline

.article-content .blog-wrapper .title-article h2
	color: #22849d
	font-weight 600

.divide
	background: linear-gradient(to left, #5dc8d8, #11738e )
	height: 5px
	display: block
	margin: 25px 0px

.article-content h3.blog-category-heading
	color: #22849d
	font-weight 600
	line-height: 25px
	font-size: 20px
	letter-spacing: 0.8px
	margin-bottom: 0px

.article-content .blog-wrapper .media .media-body h4.media-heading
	color: #22849d
	font-weight 600
	line-height: 25px

.article-content .blog-wrapper .media
	margin: 40px 0px

.article-content .blog-wrapper .media .media-body a.btn.btn-success
	border-radius: 35px
	text-decoration: none
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;
	padding: 6px 20px

.article-content .blog-wrapper .media .media-body a.btn.btn-success img
	margin-left: 5px
	margin-top: -2px

.article-content .category-wrapper hr
	border-width: 2px

.article-content .category-wrapper .panel .panel-body ul
	list-style-type: none
	margin: 0px
	padding: 0px 15px

.article-content .category-wrapper .panel .panel-body ul li
	list-style-type: none
	background: url("https://asset.healthinsurancecomparison.com.au/images/icons/blue_arrow.png") no-repeat center left
	font-size: 14px
	padding-left: 20px
	padding-top: 4px
	padding-bottom: 4px
	padding-right: 0px

.article-content .category-wrapper .panel .panel-body ul li a
	font-weight 600
	color: #000000

.article-content .category-wrapper .panel .panel-body ul li a:hover,
.article-content .category-wrapper .panel .panel-body ul li.active a,
.article-content .category-wrapper .panel .panel-body ul li.current-menu-item a,
.article-content .category-wrapper .panel .panel-body ul li a:focus
	color: #22849d
	text-decoration: none

.panel
	background-color: #fff
	background-clip: border-box
	border: 1px solid rgba(0, 0, 0, 0.125)
	border-radius: 0.5rem
	box-shadow: none
	position: relative

#page-content
	background-color: #ffffff
	z-index: 2000

.page-content-wrapper .panel .panel-body h4
	font-size: 16px
	color: #35899c
	font-weight 600

*,
*::before,
*::after
	box-sizing: border-box

.page-content-wrapper .panel .panel-body ul,
.single-meet-the-team .team-cat
	padding-left: 18px
	list-style-type: none

.page-content-wrapper .panel .panel-body ul li.title-page-content a
	font-weight 600

.page-content-wrapper .panel .panel-body ul li.active
	list-style-type: none

.page-content-wrapper .panel .panel-body ul li a.clr-active
	color: #35899c

.page-content-wrapper .panel .panel-body ul li.active::before
	color: #35899c
	content: "\2022"
	margin-left: -22px
	font-size: 30px
	margin-right: 7px
	position: relative
	top: 3px
	line-height: 18px

.page-content-wrapper .panel .panel-body ul li::before
	color: #35899c
	content: "\00b0"
	margin-left: -22px
	font-size: 28px
	margin-right: 9px
	position: relative
	top: 11px
	line-height: 18px

.page-content-wrapper .panel .panel-body ul ul
	margin-top: 10px

.page-content-wrapper .panel .panel-body ul li a,
.single-meet-the-team .team-cat li a
	color: #353535
	font-size: 14px
	line-height: 1.3 !important

.page-content-wrapper .panel .panel-body ul li ul li a
	color: #353535
	font-size: 14px
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif; !important

.page-content-wrapper .panel .panel-body:before
	content: ''
	position: absolute
	width: 101%
	height: 10px
	background: linear-gradient(to left, #5dc8d8, #11738e )
	top: -1px
	left: -1px
	border-top-left-radius: 0.5rem
	border-top-right-radius: 0.5rem

.article-content-wrapper span
	font: inherit

.article-content-wrapper .panel
	margin: 30px 0px

.article-content-wrapper 
	font-size: 1.7rem

	p 
		font-size: inherit
		margin-bottom 30px
		line-height 1.6
	ul
		list-style none
		padding-left 0

		li
			background: url(https://asset.healthinsurancecomparison.com.au/images/icons/checked.png) no-repeat left 0.4rem
			padding-left: 3rem
			padding-bottom: 2rem
			font-size: inherit
			line-height 1.6
	
	.panel-body
		padding 1.5rem 2.5rem

		li:last-child
			padding-bottom: 0

	.main-content__cta-box

		h1, h2, h3, h4, h5, h5, h6,
		.h1, .h2, .h3, .h4, .h5, .h5, .h6
			line-height 1.7
			margin 0 0 3rem
			padding-top 3rem
			
			& +
				h1, h2, h3, h4, h5, h5, h6,
				.h1, .h2, .h3, .h4, .h5, .h5, .h6
					padding-top 0
					margin-top -1.5rem



.article-content-wrapper .panel.panel-border .panel-body:before
	content: ''
	position: absolute
	width: 100%
	height: 4px
	background: linear-gradient(to left, #5dc8d8, #11738e )
	top: -1px
	left: 0px
	border-top-left-radius: 0.5rem
	border-top-right-radius: 0.5rem

.panel.panel-border .panel-body .panel-title
	font-weight 600
	margin-bottom: 1.5rem !important
	margin-top: 1rem !important
	font-size: 1.8rem

.article-content-wrapper hr
	margin-top: 2rem
	margin-bottom: 3rem
	border: 0
	border-top: 1px solid #449aae

.article-content-wrapper .title-article h1,
.article-content-wrapper .title-article h2
	font-size: 30px
	font-weight 700
	margin: 10px 0px
	line-height: 38px

.article-content-wrapper img.size-full.wp-post-image
	margin-bottom: 35px

.article-content-wrapper .wp-post-image
	max-width: 100%
	height: auto

.article-content-wrapper p:empty
	display: none

.article-content-wrapper h4,
.the_guide_content h5
	font-weight 600
	margin-bottom: 1.5rem
	margin-top: 2rem
	padding-top: 2rem
	font-size: 20px

.article-content-wrapper h4.add_offset_padding,
.the_guide_content h5.add_offset_padding
	margin-top: 0
	padding-top: 3rem
	position: relative

.bg-description
	border: 1px solid #ddd
	background: #ffffff url(https://asset.healthinsurancecomparison.com.au/images/bg_box.png)no-repeat top left
	padding: 3rem !important

.bg-description h6,
.bg-description .h6
	font-weight 600
	font-size: 18px

.bg-description .h6
	margin: 10px auto

.bg-gradient
	background: linear-gradient(to bottom, rgba(17,115,142,1) 0%,rgba(93,200,216,1) 100%)
	padding: 15px 25px
	border-radius: 4px

.bg-gradient label
	color: #ffffff

.article-content-wrapper button.btn.btn-success
	border-radius: 35px
	border: none
	color: #fff
	background-color: #71be59
	border-color: #71be59
	-webkit-box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)
	-moz-box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)
	box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)

.article-content-wrapper button.btn.btn-success:hover,
.article-content-wrapper button.btn.btn-success:focus,
.article-content-wrapper button.btn.btn-success:active,
.article-content-wrapper button.btn.btn-success.active
	background-color: #6ab553
	border-color: #6ab553

.article-content-wrapper button.btn.btn-success img
	margin-left: 10px
	margin-top: -3px
	margin-bottom: 0px
	margin-right: 0px

.form-calculator-wrapper
	margin: 20px 0px
	display: inline-block

.form-calculator-wrapper .bg-description p
	font-size: 14px

.panel.panel-border.remove-b-bottom
	border-bottom-left-radius: 0px !Important
	border-bottom-right-radius: 0px !Important
	border-bottom: 0px

.panel.panel-border.remove-b-bottom .panel-body.rebate-wrapper hr
	border-color: #ddd

.panel .panel-body.rebate-wrapper .form-group label
	display: block
	position: relative

.panel .panel-body.rebate-wrapper .form-group label span
	font-size: 13px
	border: 1px solid #ddd
	padding: 3px 10px
	border-radius: 35px
	font-weight 600

.panel .panel-body.rebate-wrapper .form-group select
	border: 1px solid #ddd

.table-calculator-wrapper .panel
	margin-bottom: 0px !Important

table.table-rebate tr.title-table th
	background-color: #f4f4f4
	font-weight 600

table.table-rebate tr th,
table.table-rebate tr td
	padding: 5px
	font-size: 14px
	text-align: center

table.table-rebate tr.no-border-bottom
	border: none

table.table-rebate tr.title-colspan td h5,
table.table-rebate tr.title-colspan td .h5
	font-weight 600
	margin: 0 0 1.5rem
	text-align: center
	padding: 2rem 0px .5rem
	font-size: 18px

table.table-rebate tr.has-bg th.bg-tbl-1
	background-color: #9d9d9d

table.table-rebate tr.has-bg th.bg-tbl-2
	background-color: #bfbfbf

table.table-rebate tr.has-bg th.bg-tbl-3
	background-color: #d4d4d4

table.table-rebate tr.has-bg th.bg-tbl-4
	background-color: #e3e3e3

table.table-rebate tr.has-bg th.bg-tbl-5
	background-color: #ececec

.other-articles-wrapper
	margin-top: 3rem !important
	display: inline-block

.other-articles-wrapper h5
	font-weight 600
	font-size: 18px
	margin-top: 15px

.other-articles-wrapper p
	font-size: 13px

.other-articles-wrapper hr
	content: ''
	width: 100%
	height: 2px
	background: linear-gradient(to left, #5dc8d8, #11738e )
	top: 0px
	left: 0px
	margin-top: 15px
	margin-bottom: 20px

.other-articles-wrapper .row a
	display: block
	position: relative
	@media (max-width: 991px)
		min-height: 285px
	@media (max-width: 767px)
		min-height: 300px
	@media (max-width: 320px)
		min-height: 330px

.other-articles-wrapper .row a:hover,
.other-articles-wrapper .row a:focus
	text-decoration: none

.other-articles-wrapper h6
	font-weight 600
	font-size: 16px
	margin-top: 15px

.other-articles-wrapper .col-lg-3
	margin-bottom: 20px

.footer__guides-style
	margin-top: 75px
	background-color: #ffffff

.footer__guides-style p
	color: #808080

.footer__guides-style .compare-button-wrapper
	background: #5dc8d8
	background: url("https://asset.healthinsurancecomparison.com.au/images/footer_bg.png") center center repeat, linear-gradient(to right,#5dc8d8, #11738e)
	padding: 1.5rem !important

.footer__guides-style .compare-button-wrapper h4
	font-weight 600
	color: #ffffff

.footer__guides-style .compare-button-wrapper h4 a
	margin-left: 10px
	margin-right: 10px

.footer__guides-style .list-footer
	padding-top: 3rem !important
	padding-bottom: 1.5rem !important

.footer__guides-style .list-footer img.logo-footer
	margin-bottom: 1.5rem

.footer__guides-style .list-footer *
	font-size: 13px

.footer__guides-style .list-footer ul li
	padding-bottom: 5px

.footer__guides-style .list-footer ul li a
	font-weight 600

output.output
	display: inline-block
	padding: 0px
	margin: 0px
	font-size: 13px
	font-weight 600

.rebate-result-wrapper
	display: block
	margin: 30px 0px 0px 0px
	border: 1px solid #ddd
	border-radius: 3px
	padding: 15px
	text-align: center
	font-size: 20px
	font-weight 600

.rebate-result-wrapper span
	font-size: 20px
	font-weight 600
	color: #35899c

.article-content-wrapper #lead-form.well
	box-shadow: none
	border: none
	background: linear-gradient(to left, #5dc8d8, #11738e )
	margin: 30px 0px 15px 0px
	color: #ffffff

.article-content-wrapper #lead-form.well .media .media-left
	padding-right: 15px

.article-content-wrapper #lead-form.well .media .media-body h4.media-heading
	color: #fff
	margin-top: 6px !important
	padding-top: 0px !important

.article-content-wrapper #lead-form.well .media .media-body p
	font-size: 16px
	color: #ffffff
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;

.article-content-wrapper #lead-form.well .media .media-body p a
	background-color: #71be59
	color: #ffffff
	padding: 3px 10px
	border-radius: 3px
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;

.article-content-wrapper #lead-form.well .media .media-body .row .form-control::placeholder,
.article-content-wrapper #lead-form.well .media .media-body .row .form-control
	border: none
	color: #000
	margin-bottom: 7px
	border-radius: 3px

.article-content-wrapper #lead-form.well .media .media-body p a:hover,
.article-content-wrapper #lead-form.well .media .media-body p a:focus
	text-decoration: none

.article-content-wrapper #lead-form.well .media .media-body .btn.btn-success
	display: block
	margin: 15px 0px
	font-size: 16px
	letter-spacing: 1px

.article-content-wrapper #comment-wrapper.well
	box-shadow: none
	border: 1px solid #ffffff
	background-color: #ffffff
	margin: 15px 0px 15px 0px

.article-content-wrapper #comment-wrapper.well .header-comment
	border-bottom: 1px solid #ebebeb

.article-content-wrapper #comment-wrapper.well .header-comment h4
	margin-top: 0px !Important
	font-size: 18px

.article-content-wrapper #comment-wrapper.well .header-comment h4 a
	float: right
	color: #000
	font-weight 600

.article-content-wrapper #comment-wrapper.well .header-comment h4 a:hover,
.article-content-wrapper #comment-wrapper.well .header-comment h4 a:focus
	text-decoration: none
	font-weight 600

.article-content-wrapper #comment-wrapper.well .header-comment h4 a img
	margin: 0px

.article-content-wrapper #comment-wrapper.well .media .media-body h5.media-heading
	font-weight 600
	color: #177c96
	font-size: 16px

.article-content-wrapper #comment-wrapper.well .media .media-body h5.media-heading small
	font-size: 14px
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;
	margin-left: 3px

.article-content-wrapper #comment-wrapper.well .media .media-body .row .form-control
	border: none
	background: transparent
	box-shadow: none
	font-size: 16px
	border-radius: 35px

.article-content-wrapper #comment-wrapper.well .media .media-body.comment-form
	border: 1px solid #ebebeb
	padding: 5px

.article-content-wrapper #comment-wrapper.well .media .media-body .row button.btn.btn-success
	border-radius: 35px
	border: none
	color: #fff
	background-color: #71be59
	border-color: #71be59
	box-shadow: none
	padding: 8px 15px

.article-content-wrapper #comment-wrapper.well .media .media-left a img
	margin: 0px

.article-content-wrapper #comment-wrapper.well .media
	margin-top: 20px

.article-content-wrapper #comment-wrapper.well .media .media-body p
	margin-bottom: 5px

.article-content-wrapper #comment-wrapper.well .media .media-body ul
	margin: 0px

.article-content-wrapper #comment-wrapper.well .media .media-body ul li:first-child
	margin: 0px
	padding-left: 0px
	border-right: 1px solid #999

.article-content-wrapper #comment-wrapper.well .media .media-body ul li
	margin: 0px
	padding: 0px 5px

.article-content-wrapper #comment-wrapper.well .media .media-body ul li a
	opacity: 0.5

.article-content-wrapper #comment-wrapper.well .media .media-body ul li a:hover,
.article-content-wrapper #comment-wrapper.well .media .media-body ul li a:focus
	opacity: 1
	text-decoration: none

.article-content-wrapper #comment-wrapper.well ul
	display: block
	list-style-type: none
	padding: 0px
	margin: 10px 0px
	background: none

.article-content-wrapper #comment-wrapper.well ul li
	display: inline-block
	list-style-type: none
	padding: 0px
	margin: 0px
	background: none

.article-content-wrapper #comment-wrapper.well ul li:first-child
	margin-right: 10px

.article-content-wrapper #comment-wrapper.well ul li a
	color: #000000
	font-size: 14px
	font-weight 600

.article-content-wrapper #comment-wrapper.well ul li a:hover,
.article-content-wrapper #comment-wrapper.well ul li a:focus
	text-decoration: none

.article-content-wrapper #comment-wrapper.well ul li a img
	margin: -3px 3px 0px 0px

.article-content-wrapper .well
	box-shadow: none
	border: none
	background-color: #f5f5f5
	margin: 30px 0px 15px 0px

.article-content-wrapper #author.well .media .media-body small
	color: #888888
	margin: 0px

.article-content-wrapper #author.well .media .media-body h2
	font-weight 700
	margin: 5px 0px

.article-content-wrapper #author.well .media .media-body h6
	font-weight 600
	font-size: 14px
	color: #35899c

.article-content-wrapper #author.well .media .media-body p
	font-size: 14px

.article-content-wrapper #author.well .media .media-body ul
	list-style-type: none
	margin: 0px
	padding: 0px

.article-content-wrapper #author.well .media .media-body ul li::before,
.article-content-wrapper #author.well .media .media-body ul li
	list-style-type: none
	background: none
	padding: 0px

.article-content-wrapper #author.well .media .media-body ul li
	margin: 3px 0px

.article-content-wrapper #author.well .media .media-body ul li a
	padding: 8px 10px
	border-radius: 35px
	background-color: #ffffff
	list-style-type: none
	border: 1px solid #ddd
	display: inline-block

.article-content-wrapper #author.well .media .media-body ul li a:hover,
.article-content-wrapper #author.well .media .media-body ul li a:focus
	border: 1px solid #35899c

.article-content-wrapper .well .media .media-body ul li a img
	padding: 0px
	margin: 0px

.nav-hide
	display: none

.article-content-wrapper #faq-wrapper .panel-group .panel
	border-color: #ddd
	margin: 5px 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading
	background: #ffffff
	color: #35899c
	border-radius: 5px
	border-color: #ddd

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading h5.panel-title a
	font-weight 600
	display: block
	opacity: 1
	color: #35899c

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading h5.panel-title a img
	margin-top: -2px !important
	margin-right: 7px !important

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading h5.panel-title a.collapsed img.plus
	display: inline-block
	padding: 0px
	margin: 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading h5.panel-title a.collapsed img.minus
	display: none
	padding: 0px
	margin: 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading h5.panel-title a img.plus
	display: none
	padding: 0px
	margin: 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading h5.panel-title a img.minus
	display: inline-block
	padding: 0px
	margin: 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading h5.panel-title a:hover,
.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading h5.panel-title a:focus
	text-decoration: none

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-body
	padding: 30px 35px
	border-color: #ddd

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-body p strong
	font-weight 700
	color: #11738e

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-body a
	text-decoration: underline

.other-articles-wrapper .row a
	display: block
	position: relative

.other-articles-wrapper .row a
	display: block
	position: relative
	background-color: #ffffff
	-webkit-box-shadow: 0px 10px 26px -4px rgba(0,0,0,0.09)
	-moz-box-shadow: 0px 10px 26px -4px rgba(0,0,0,0.09)
	box-shadow: 0px 10px 26px -4px rgba(0,0,0,0.09)
	-webkit-transition: box-shadow 1s
	/* Safari */
	transition: box-shadow 1s

.other-articles-wrapper .row a:hover
	-webkit-box-shadow: 0px 10px 26px -4px rgba(0,0,0,2)
	-moz-box-shadow: 0px 10px 26px -4px rgba(0,0,0,0.2)
	box-shadow: 0px 10px 26px -4px rgba(0,0,0,0.2)

.other-articles-wrapper .row a .pd-other-articles
	padding: 15px

.other-articles-wrapper .row a .pd-other-articles h6
	margin-top: 0px

.other-articles-wrapper .row a .pd-other-articles p
	margin: 0px

.other-articles-wrapper .row a:hover,
.other-articles-wrapper .row a:focus
	text-decoration: none

.navbar
	border: 0px solid transparent

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu
	z-index: 5000
	border-top: 4px solid #71be59
	border-left: none
	border-right: none
	border-bottom: none
	border-radius: 0px
	background-color: #3c7b8d

.navbar-nav > li > a
	color: #000
	background: none
	transition: background .25s ease-in-out
	-moz-transition: background .25s ease-in-out
	-webkit-transition: background .25s ease-in-out
	padding: 25px
	font-size: 14px

#navbar.navbar-collapse.navbar-collapse-v2
	.navbar-nav.navbar-nav-v2 > li > a
		padding: 25px 15px
	.btn.btn-block
		color: #fff
	.btn.btn-default
		margin-top: 0
	a.contact-wrapper
		h6
			top: 0
			text-align: left
		small
			text-align:left
			margin-top: 0
	@media(max-width: 1199px)
		position static
		padding: 0
		#menu_searchform
			right: 0
		.navbar-nav.navbar-nav-v2 > li > a
			padding: 20px
			font-size: 14px
		a.contact-wrapper
			position: relative
			right: 0
			width: 100%
			text-align: right
			img
				float: none
	@media(max-width: 991px)
		.navbar-nav.navbar-nav-v2 > li > a
			padding: 20px 10px
			font-size: 12px
	@media(max-width: 767px)
		padding: 15px
		a.contact-wrapper
			position: absolute
			width: 172px
			img
				position: absolute
				margin: 0
				top: 15px
				left: -20px
		
	 

.navbar-nav > li > a:hover,
.navbar-nav > li.active > a,
.navbar-nav > li > a:focus
	color: #71be59
	background: #ffffff

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list
	list-style-type: none
	margin: 0px
	padding: 0px

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list li
	list-style-type: none
	padding: 8px 0px 8px 17px
	background: url("https://asset.healthinsurancecomparison.com.au/images/icons/green_arrow.png") left 1.3rem no-repeat
	color: #ffffff

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list.no-list li
	background: none !important
	padding: 8px 0px 8px 0px

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list.no-list.un-pd li a
	font-size: 14px



nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list li a
	color: #ffffff
	font-size: 16px
	font-weight 600

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list li a img
	margin-top: -2px
	margin-right: 4px

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list li a:hover,
nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list li a:focus
	text-decoration: none

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.req-call-link
	color: #ffffff
	font-size: 12px
	display: block
	text-align: center
	margin: 15px 0px

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.req-call-link img
	margin-right: 5px
	margin-top: -3px

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-success
	border-radius: 35px
	font-size: 15px
	font-weight 600

img.dropdown-arrow
	position: absolute
	z-index: 5000
	bottom: 0
	text-align: center
	right: 0

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw.open a.dropdown-toggle:hover,
nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw.open a.dropdown-toggle:visited,
nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw.open a.dropdown-toggle:focus
	background: #ffffff
	color: #71be59

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw.open a.dropdown-toggle img.dropdown-arrow
	opacity: 1

.contact-wrapper
	padding: 13px 0
	margin-top 6px

.contact-wrapper img
	float: left
	margin: 2px 7px 3px 0px

.contact-wrapper small
	font-size: 9px
	color: #35899c
	word-wrap: break-word
	letter-spacing: 0px
	display: block
	text-align: center
	font-weight 600

.contact-wrapper h6
	display: inline-block
	font-size: 22px
	color: #000000
	font-weight 300
	margin: 0px
	letter-spacing: 0.8px
	float: right
	text-align: center

	@media (min-width 1200px)
		position relative
		top -10px

.contact-wrapper:hover h6,
.contact-wrapper:focus h6
	color: #71be59

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list li a small
	font-size: 13px
	line-height: normal
	display: block
	padding-top: 10px

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default
	background: transparent
	font-size: 15px
	font-weight 600
	border: 2px solid #ffffff
	color: #ffffff
	padding: 6px 22px

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default:hover,
nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default:focus
	color: #fff
	background-color: #5cb85c
	border-color: #5cb85c

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw a.dropdown-toggle img.dropdown-arrow
	opacity: 0

.blog-lists-wrapper
	display: block
	margin: 35px 0px

/* WP-COMMENT-WRAPPER */
@media (min-width: 320px)
	.article-content
		padding: 1rem

	a.compare-button
		margin-top: 20px
		display: block

	.page-content-wrapper
		display: none

	.bg-description
		margin-top: 0px
		margin-bottom: 0px

	.bg-gradient
		margin-left: 0px

	.bg-description
		border-top-left-radius: 0px
		border-bottom-left-radius: 0px

	.panel .panel-body.rebate-wrapper
		padding: 1.5rem 1.5rem

	.navbar-nav > li
		border-top: 1px solid #eee

	.article-content-wrapper #comment-wrapper.well .media .media-body.comment-form
		border-radius: 5px

	.article-content-wrapper #author.well .media .media-left
		padding-right: 10px

	.panel .panel-body.rebate-wrapper .form-group label span
		float: none
		display: block
		width: 120px
		margin: 5px auto
		text-align: center

	.panel .panel-body.rebate-wrapper .form-group label
		text-align: center

	a.contact-wrapper
		display: none

	.mt-mobile
		margin-top: 25px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row.mb-megamenu
		margin-bottom: 0px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default
		margin-top: 5%

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu
		padding: 15px 0px

	img.dropdown-arrow
		margin: 0px 0px
		left: 15px

	.divide
		width: 100%

	.article-content .blog-wrapper .media .media-left img
		width: 130px
		height: auto

	.article-content .blog-wrapper .media .media-left
		padding-right: 15px

@media (min-width: 480px)
	.article-content
		padding: 1rem

	a.compare-button
		margin-top: 20px
		display: block

	.page-content-wrapper
		display: none

	.bg-description
		margin-top: 0px
		margin-bottom: 0px

	.bg-gradient
		margin-left: 0px

	.bg-description
		border-top-left-radius: 0px
		border-bottom-left-radius: 0px

	.panel .panel-body.rebate-wrapper
		padding: 1.5rem 1.5rem

	.navbar-nav > li
		border-top: 1px solid #eee

	.article-content-wrapper #comment-wrapper.well .media .media-body.comment-form
		border-radius: 5px

	.article-content-wrapper #author.well .media .media-left
		padding-right: 10px

	.panel .panel-body.rebate-wrapper .form-group label
		text-align: center

	a.contact-wrapper
		display: none

	.mt-mobile
		margin-top: 25px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row.mb-megamenu
		margin-bottom: 0px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default
		margin-top: 5%

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu
		padding: 15px 0px

	img.dropdown-arrow
		margin: 0px 0px
		left: 15px

	.divide
		width: 100%

	.article-content .blog-wrapper .media .media-left img
		width: 150px

	.article-content .blog-wrapper .media .media-left
		padding-right: 15px

@media (min-width: 768px)
	.article-content
		padding: 2rem

	a.compare-button
		margin-top: 0px
		display: inline-block

	.page-content-wrapper
		display: none

	.bg-description
		margin-top: 12px
		margin-bottom: 0px

	.bg-gradient
		margin-left: -1px

	.bg-description
		border-top-left-radius: 0px
		border-bottom-left-radius: 0px

	.panel .panel-body.rebate-wrapper
		padding: 1.5rem 2rem

	.navbar-nav > li
		border-top: 0px solid #eee

	.article-content-wrapper #comment-wrapper.well .media .media-body.comment-form
		border-radius: 35px

	.article-content-wrapper #author.well .media .media-left
		padding-right: 25px

	.panel .panel-body.rebate-wrapper .form-group label
		text-align: left

	a.contact-wrapper
		display: none

	.mt-mobile
		margin-top: 5px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row.mb-megamenu
		margin-bottom: 0px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default
		margin-top: 20%

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu
		padding: 25px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw a.dropdown-toggle:hover img.dropdown-arrow,
	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw a.dropdown-toggle:focus img.dropdown-arrow
		opacity: 1

	img.dropdown-arrow
		margin: 0px auto
		left: 0

	.divide
		width: 100px

	.article-content .blog-wrapper .media .media-left img
		width: 180px

	.article-content .blog-wrapper .media .media-left
		padding-right: 15px

@media (min-width: 992px)
	.article-content
		padding: 3rem

	a.compare-button
		margin-top: 0px
		display: inline-block

	.page-content-wrapper
		display: block

	.bg-description
		margin-top: 12px
		margin-bottom: 0px

	.bg-gradient
		margin-left: -1px

	.bg-description
		border-top-left-radius: 4px
		border-bottom-left-radius: 4px

	.panel .panel-body.rebate-wrapper
		padding: 2rem 3rem

	.navbar-nav > li
		border-top: 0px solid #eee

	#page-content
		width: 257px

	.article-content-wrapper #comment-wrapper.well .media .media-body.comment-form
		border-radius: 35px

	.article-content-wrapper #author.well .media .media-left
		padding-right: 25px

	.panel .panel-body.rebate-wrapper .form-group label
		text-align: left

	a.contact-wrapper
		display: none

	.dropdown:hover .dropdown-menu
		display: block
		margin-top: 0

	.mt-mobile
		margin-top: 5px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row.mb-megamenu
		margin-bottom: 20px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default
		margin-top: 20%

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu
		padding: 25px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw a.dropdown-toggle:hover img.dropdown-arrow,
	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw a.dropdown-toggle:focus img.dropdown-arrow
		opacity: 1

	img.dropdown-arrow
		margin: 0px auto
		left: 0

	.divide
		width: 100px


	.article-content .blog-wrapper .media .media-left img
		width: 210px

	.article-content .blog-wrapper .media .media-left
		padding-right: 25px

@media (min-width: 1200px)
	a.compare-button
		margin-top: 0px
		display: inline-block

	.page-content-wrapper
		display: block

	.bg-description
		margin-top: 12px
		margin-bottom: 0px

	.bg-gradient
		margin-left: -1px

	.bg-description
		border-top-left-radius: 4px
		border-bottom-left-radius: 4px

	.panel .panel-body.rebate-wrapper
		padding: 2.5rem 3.5rem

	.navbar-nav > li
		border-top: 0px solid #eee

	#page-content
		width: 248px

	.article-content-wrapper #comment-wrapper.well .media .media-body.comment-form
		border-radius: 35px

	.article-content-wrapper #author.well .media .media-left
		padding-right: 25px

	.panel .panel-body.rebate-wrapper .form-group label
		text-align: left

	a.contact-wrapper
		display: block

	.dropdown:hover .dropdown-menu
		display: block
		margin-top: 0

	.mt-mobile
		margin-top: 0

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row.mb-megamenu
		margin-bottom: 20px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default
		margin-top: 20%

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu
		padding: 25px

	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw a.dropdown-toggle:hover img.dropdown-arrow,
	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw a.dropdown-toggle:focus img.dropdown-arrow
		opacity: 1

	img.dropdown-arrow
		margin: 0px auto
		left: 0

	.divide
		width: 100px

	.article-content .blog-wrapper .media .media-left img
		width: 250px

	.article-content .blog-wrapper .media .media-left
		padding-right: 25px

/*style overwrite*/
#toc_container
	display: none !important

.toc_list > li a
	font-weight 600
	padding: 0

.page-content-wrapper .panel .panel-body ul ul
	margin: 5px 0 10px

.calculator-title
	font-weight 600
	font-size: 18px

#result-table-waiting
	border-top: 4px solid #5cc7d7
	display: none

#result-table-waiting table
	background: #fff
	margin: 0

#result-table-waiting td,
#result-table-waiting th
	padding: 10px

#result-table-waiting th
	font-weight: normal

#result-table-waiting i
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;

#result-table-waiting .selected_procedure,
#result-table-waiting .selected_state
	font-weight 600

#result-table-waiting strong
	display: block
	font-weight 700

#result-table-waiting td
	vertical-align: middle
	background-color: #f4f4f4

#result-table-waiting span
	width: 100px
	display: inline-block
	white-space: nowrap
	text-align: center
	font-weight 700

.guides_page .autocomplete-suggestions
	text-align: left
	cursor: default
	border: 1px solid #ccc
	border-top: 0
	background: #fff
	-webkit-box-shadow: -1px 1px 3px rgba(0,0,0,0.1)
	box-shadow: -1px 1px 3px rgba(0,0,0,0.1)
	position: absolute
	display: none
	z-index: 9999
	max-height: 254px
	overflow: hidden
	overflow-y: auto
	// margin-top -34px

.guides_page .autocomplete-suggestions .autocomplete-suggestion
	position: relative
	padding: 10px
	line-height: 23px
	white-space: nowrap
	overflow: hidden
	font-size: 14px
	color: #888
	cursor: pointer

.guides_page .autocomplete-suggestions .autocomplete-suggestion b
	color: #459bb0

.guides_page .autocomplete-suggestions .autocomplete-suggestion.selected
	background: #f0f0f0

@media (max-width: 767px)
	.guides_page .autocomplete-suggestions .autocomplete-suggestion
		font-size: 13px
		line-height: 1.3
		padding: 7px 10px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading .h5.panel-title a
	font-weight 600
	display: block
	opacity: 1
	color: #35899c

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading .h5.panel-title a img
	margin-top: -2px !important
	margin-right: 7px !important

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading .h5.panel-title a.collapsed img.plus
	display: inline-block
	padding: 0px
	margin: 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading .h5.panel-title a.collapsed img.minus
	display: none
	padding: 0px
	margin: 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading .h5.panel-title a img.plus
	display: none
	padding: 0px
	margin: 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading .h5.panel-title a img.minus
	display: inline-block
	padding: 0px
	margin: 0px

.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading .h5.panel-title a:hover,
.article-content-wrapper #faq-wrapper .panel-group .panel .panel-heading .h5.panel-title a:focus
	text-decoration: none

/*calculator update*/
@media (max-width: 767px)
	.panel .panel-body.rebate-wrapper .form-group label
		text-align: center

@media (min-width: 320px)
	.panel .panel-body.rebate-wrapper .form-group label span
		float: none
		display: block
		width: 120px
		margin: 5px auto
		text-align: center

@media (min-width: 480px)
	.panel .panel-body.rebate-wrapper .form-group label span
		float: none
		display: block
		width: 120px
		margin: 5px auto

@media (min-width: 768px)
	.panel .panel-body.rebate-wrapper .form-group label span
		float: right
		display: inline-block
		width: auto
		margin: 0px 0px
		text-align: center

@media (min-width: 992px)
	.panel .panel-body.rebate-wrapper .form-group label span
		float: right
		display: inline-block
		width: auto
		margin: 0px 0px
		text-align: center

@media (min-width: 1200px)
	.panel .panel-body.rebate-wrapper .form-group label span
		float: right
		display: inline-block
		width: auto
		margin: 0px 0px
		text-align: center

/* WP-COMMENT-WRAPPER */
#wp-comment-wrapper
	margin: 35px 0px

#wp-comment-wrapper .media
	margin-bottom: 60px

#wp-comment-wrapper .media .media-body h5
	font-weight 700
	font-size: 16px

#wp-comment-wrapper .media .media-body small
	text-transform: uppercase
	font-size: 12px
	font-weight 600
	color: #666
	letter-spacing: 1px

#wp-comment-wrapper .media .media-body p
	margin: 15px 0px

#wp-comment-wrapper .media .media-body a
	font-weight 600
	color: #000
	margin-left: -28px
	z-index: 5000
	position: absolute
	display: block

#wp-comment-wrapper .media .media-body a:hover,
#wp-comment-wrapper .media .media-body a:focus
	text-decoration: none

#wp-comment-wrapper .media .media-body a img
	margin-top: -4px
	margin-right: 10px

#wp-comment-wrapper .media .media-body .comment-respond a
	position: relative
	margin: 10px 0

#wp-comment-wrapper .media .media-body h4
	font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;
	margin: 0px !important
	padding: 0px !important

#wp-comment-wrapper .media .media-body .form-control
	box-shadow: none

#wp-comment-wrapper .media .media-body button.btn.btn-default
	color: #ffffff
	background-color: #000000
	border-color: #000000
	font-weight 600

.article-content-wrapper #wp-comment-wrapper img
	margin-bottom: 0px

.article-content-wrapper #wp-comment-wrapper .media .media-left img
	margin: 0px

.article-content-wrapper #wp-comment-wrapper .media .media-left
	padding-right: 20px

.page-content-wrapper .panel .panel-body .toc_list li > a + ul
	padding-left: 18px

.page-content-wrapper .panel .panel-body .toc_list li > ul
	padding-left: 0

.page-content-wrapper .panel .panel-body .toc_list li:before
	display: none

.page-content-wrapper .panel .panel-body .toc_list li > a:hover,
.page-content-wrapper .panel .panel-body .toc_list li > a:focus,
.page-content-wrapper .panel .panel-body .toc_list li > a:active
	text-decoration: none
	color: #000

.page-content-wrapper .panel .panel-body .toc_list li > a:before
	color: #35899c
	content: "\00b0"
	margin-left: -22px
	font-size: 28px
	margin-right: 9px
	position: relative
	top: 11px
	line-height: 18px

.page-content-wrapper .panel .panel-body .toc_list li.active > a:before
	content: "\2022"
	line-height: 18px
	top: 3px

/* yamm */
.yamm .nav,
.yamm .collapse,
.yamm .dropup,
.yamm .dropdown
	position: static

.yamm .container
	position: static
	max-width: 1280px
	width: auto
	padding: 0 20px
	
.yamm.yamm_v2 .container
	max-width: 1400px
	.navbar-nav > li > a
		padding: 25px 15px
	ul.nav.navbar-nav li.dropdown.yamm-fw ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default
		margin-top: 0
	.contact-wrapper 
		h6
			text-align:left
			margin-top:13px
			@media(max-width: 1199px)
				margin-top: 8px
				font-size: 18px
	@media(max-width: 991px)
		#navbar.navbar-collapse 
			#menu_searchform
				right: 0
			a.contact-wrapper
				position: relative
				right: 0
				text-align: right
				width: 100%
				img
					float: none
	@media(max-width: 767px)	
		#navbar.navbar-collapse a.contact-wrapper
			position: absolute
			width: 172px		

.yamm .dropdown-menu
	left: auto

.yamm .yamm-content
	padding: 20px 30px

.yamm .dropdown.yamm-fw .dropdown-menu
	left: 0
	right: 0

/* calculator update */
.article-content-wrapper .table-calculator-wrapper .panel .panel-body .h4
	padding-top: 0px !important
	margin-top: 0px !important

.article-content-wrapper .table-calculator-wrapper .panel .panel-body .rebate-result-wrapper.text-left
	text-align: left !important

.article-content-wrapper .table-calculator-wrapper .panel .panel-body .rebate-result-wrapper span.hl-text
	font-size: 15px
	border-bottom: 1px dotted #35899c

.article-content-wrapper .table-calculator-wrapper .panel .panel-body form input#calendar-pick
	background: url("https://asset.healthinsurancecomparison.com.au/images/icons/calendar.svg") no-repeat center right 0.6rem
	background-size: 20px

#result
	display: none

.article-content-wrapper .table-calculator-wrapper .has-list table tr td ul li
	text-align: left

.article-content-wrapper .table-calculator-wrapper .has-list table tr td ul li:last-child
	padding-bottom: 0px

.article-content-wrapper .table-calculator-wrapper .has-list table tr td ul
	margin-left: 15px

.article-content-wrapper .table-calculator-wrapper .has-list table tr td:first-child
	vertical-align: middle
	font-size: 14px
	color: #35899c
	background-color: #f9f9f9
	font-weight 600

.article-content-wrapper .table-calculator-wrapper .has-list table tr th:last-child
	text-align: left
	padding-left: 15px

.article-content-wrapper .form-calculator-wrapper form input.form-control
	border: none

.article-content-wrapper .form-calculator-wrapper form .radio input
	border: none

.article-content-wrapper .form-calculator-wrapper form .radio
	margin: 7px 0px

.article-content-wrapper .form-calculator-wrapper form .radio label
	font-size: 14px
	font-weight 600

.article-content-wrapper .form-calculator-wrapper form .radio label span
	font-size: 13px
	color: #ffffff
	border-bottom: 1px dotted #ffffff
	font-weight 600

table.table.table-hl tr td:first-child
	background: #fafafa

table.table.table-hl
	position: relative

table.table.table-hl:before
	content: ''
	position: absolute
	width: 100%
	margin-top: -2px
	height: 4px
	background: linear-gradient(to left, #5dc8d8, #11738e )

@media (min-width: 1200px)
	.page-template-tpl-coming-soon .article-content
		padding: 5rem

.page-template-tpl-coming-soon .article-content
	padding-bottom: 0
	min-height: 0

.coming-soon-section
	position: relative
	min-height: 39rem
	padding: 76px 4rem 4rem 28rem
	font-size: 1.6rem

.coming-soon-section img
	position: absolute
	left: 0
	bottom: 0
	top: auto
	margin: 0

.coming-soon-section h1
	margin: 0 0 25px
	font-size: 4.9rem
	color: #167a95

.coming-soon-section strong
	font-weight 700

.coming-soon-section p
	font-size: inherit
	line-height: 1.5em
	margin: 0 0 2.1em

.coming-soon-section a
	background: #71be59
	font-size: 1.6rem
	font-weight 700
	color: #fff
	padding: 1.8rem 3.2rem
	border-radius: 500px
	transition: .7s ease-in-out

.coming-soon-section a:hover,
.coming-soon-section a:focus
	color: #fff
	background: #167a95

.page-content-wrapper #sidebar-form
	display: none

#sidebar-form,
#compare-form,
#compare-form2
	border: 0
	// border-radius: 10px
	box-shadow: none
	padding: 2.5rem
	background: linear-gradient(135deg, rgba(21,120,148,1) 0%,rgba(85,191,209,1) 100%)
	color: #fff
	width: 280px
	max-width: 100%
	margin: 0 auto
	text-align: center

#sidebar-form h3,
#compare-form h3
#compare-form2 h3
	font-weight: 400
	margin: 0 5px 2rem
	font-size: 2.2rem
	text-align: left

#compare-form, #compare-form2
	width: 200px
	margin-top: 20px
	padding: 15px
	margin-left: 0
	font-size: 1.3rem

#compare-form h3, #compare-form2
	font-size: 2rem
	margin-top: 0 0 20px 0

#compare-form  #lifestage,
#compare-form2 #lifestage
	background: url(https://asset.healthinsurancecomparison.com.au/images/man-user.png) no-repeat .9rem 50%,url(https://asset.healthinsurancecomparison.com.au/images/dropdown-arrow.png) no-repeat 95% 50%
	-webkit-background-size: auto,14px 8px
	-moz-background-size: auto,14px 8px
	background-size: auto,14px 8px
	background-color: #fff
	padding-left: 30px
	font-size: 12px
	appearance: none

#compare-form #postcode,
#compare-form2 #postcode
	background: #fff url(https://asset.healthinsurancecomparison.com.au/images/map-icon.png) no-repeat .9rem 50%
	padding-left: 30px
	font-size: 12px

@media (min-width: 992px)
	#sidebar-form.affix
		position: fixed !important
		top: 50px

	#sidebar-form.affix-bottom
		position: absolute

#sidebar-form strong
	font-weight 700

#sidebar-form .form-control
	box-shadow: none
	border: 0
	padding-left: 3.7rem
	padding-right: 2.7rem
	-webkit-appearance: none
	-moz-appearance: none
	appearance: none

#sidebar-form .form-control::-ms-expand
	display: none
	opacity: 0
	visibility: hidden

#sidebar-form .form-control,
#sidebar-form .form-control::placeholder
	color: #000

#sidebar-form #lifestage
	background: url(https://asset.healthinsurancecomparison.com.au/images/man-user.png) no-repeat 1.5rem 50%, url(https://asset.healthinsurancecomparison.com.au/images/dropdown-arrow.png) no-repeat 95% 50%
	background-size: auto, 14px 8px
	background-color: #fff

#sidebar-form #postcode
	background: #fff url(https://asset.healthinsurancecomparison.com.au/images/map-icon.png) no-repeat 1.5rem 50%

#sidebar-form .btn-success,
#compare-form .btn-success,
#compare-form2 .btn-success
	font-size: 1.7rem
	line-height: 1
	padding: 1.5rem
	border-radius: 500px
	font-weight 700
	margin: 0 auto 1.4rem

#compare-form .btn-success,
#compare-form2 .btn-success
	font-size: 1.4rem
	padding: 1.3rem

#sidebar-form a,
#compare-form a,
#compare-form2 a
	display: block
	padding: 10px
	color: #fff
	font-size: 2rem
	font-weight 600
	line-height: 2.2rem

#sidebar-form a:hover,
#sidebar-form a:focus
	text-decoration: none

#sidebar-form img
	margin: 0 5px 0 0
	position: relative
	top: -2px

@media (max-width: 1199px)
	.coming-soon-section h1
		font-size: 4rem


@media (max-width: 991px)
	#sidebar-form
		width: 100%
		margin: 0 auto 20px
		padding: 3rem 5rem

	#sidebar-form  h3
		text-align: center

	#sidebar-form .form-group
		float: left
		width: 50%
		padding: 0 5px

	#sidebar-form .btn-success
		width: 70%
		clear: both

@media (max-width: 767px)
	.coming-soon-section
		padding: 3rem 3rem 0

	.coming-soon-section h1
		font-size: 3rem

	.coming-soon-section img
		position: static
		display: block
		margin: 0 auto
		max-width: 70%
		width: auto

	#sidebar-form
		margin-top: -33px
		padding: 2rem

	#sidebar-form .form-group
		width: 100%
		padding: 0

	#sidebar-form .btn-success
		max-width: 100%
		width: 100%

	.page-template-tpl-coming-soon footer
		margin-top: 3rem

#go-top-btn
	display: block
	position: fixed
	top: auto
	bottom: 50px
	right: 50px
	padding: 5px
	border-radius: 4px
	background: rgba(255,255,255,.7)
	transition: .5s ease-in-out
	font-size: 14px

@media (max-width: 767px)
	#go-top-btn
		bottom: 20px
		right: 20px

.container-with-icon
	position: relative
	padding: 0
	margin: 0 0 25px
	min-height: 60px
	vertical-align: middle
	display: table
	width: 100%

.container-with-icon div
	width: 80px

.container-with-icon > div img
	width: 100%
	min-width: 60px
	max-width: 60px
	margin: 0

.container-with-icon div,
.container-with-icon p
	display: table-cell
	vertical-align: middle
	padding: 0
	margin: 0

@media (max-width: 500px)
	.container-with-icon div,
	.container-with-icon p
		vertical-align: top

.article-content-wrapper img.alignleft
	float: left
	margin: 0 20px 30px 0

.article-content-wrapper img.alignright
	float: right
	margin: 0 0 20px 30px

.article-content-wrapper .wp-caption
	padding: 10px
	border: 1px solid #eee
	max-width: 100%
	margin: 20px auto

.article-content-wrapper .wp-caption img
	border: 1px solid #eee
	max-width: 100%
	margin: 0 auto 5px

.article-content-wrapper .wp-caption p
	text-align: center
	margin: 0 0 5px

/* blogger section */
.blogger-section
	margin: 0 auto 20px
	text-align: center
	padding-top: 20px
	border-top: 1px solid #ddd
	position: relative

.blogger-section:before
	content: ''
	display: block
	position: absolute
	top: 0
	left: 50%
	margin-left: -10px
	width: 0
	height: 0
	border-left: 10px solid transparent
	border-right: 10px solid transparent
	border-top: 10px solid #ddd

.blogger-section img
	width: 100px
	max-width: 100px
	height: auto
	margin: 10px auto 5px

blockquote.blogger-comment
	margin: 0 auto 20px
	position: relative
	padding-left: 60px
	padding-top: 0
	padding-bottom: 20px
	border: none
	border-bottom: 1px solid #ddd
	font-size: 14px
	font-style: italic

blockquote.blogger-comment:before
	content: ''
	width: 40px
	height: 37px
	background: url("https://asset.healthinsurancecomparison.com.au/images/quote-img.jpg") no-repeat
	font-family: Arial, Helvetica, sans-serif
	font-size: 60px
	display: block
	position: absolute
	left: 0
	top: 0

@media screen and (max-width: 1270px)
	.navbar-nav
		& > li
			& > a
				padding 25px

@media screen and (max-width: 1210px)
	.navbar-nav
		& > li
			& > a
				padding 20px

@media screen and (max-width: 1210px)
	.navbar > .container .navbar-brand , .contact-wrapper
		margin 0

@media screen and (max-width: 1200px)
	.navbar-nav
		& > li
			& > a
				padding 20px 15px
				font-size 14px

@media screen and (max-width:  990px)
	.navbar-nav
		& > li
			& > a
				padding 20px 10px
				font-size 14px


@import 'calculator_update';
@import 'blog_guides';
@import 'page_company';
@import 'about';
@import 'homepage_brandtoolkit';
@import 'guide_archive'



// search menu
#menu_searchform
	width 27px
	height 27px
	position relative
	z-index: 3
	margin 21px 20px 0 5px

	input
		display block
		outline 0
		transition 1s ease-in-out
		height 25px
		line-height normal
		font-size 12px
		color #6c6c6c
		border 1px solid #489eb3
		border-radius 500px
		position absolute
		top 1px
		right 1px
		left auto
		z-index 2
		width 155px
		max-width 25px
		padding 0 0 0 17px

	.search-btn
		position absolute
		width 27px
		height 27px
		border-radius 500px
		transition: .5s ease-in-out
		background #e7e7e7 url(https://asset.healthinsurancecomparison.com.au/images/searchform_btn_icon.png) no-repeat 0 0
		z-index: 3
		top 0
		left 0
		border 0
		outline 0

	&.active
		input
			max-width 155px
			right 1px
			padding 0 20px 0 17px

		.search-btn
			background-position -27px 0
			background-color #8cc640

	@media (max-width 1199px)
		margin-top 15px

	@media (max-width 991px)
		display none

	@media (max-width 767px)
		display block
		width 100%
		height 40px
		margin-left 0

		.search-btn
			left auto
			right 10px
			top 7px

		input
			width 100%
			max-width 100%
			height: 40px
			top 0
			right 0

		&.active
			input
				width: 100%
				max-width: 100%
				right 0

nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw.guides-dropdown ul.dropdown-menu li.grid-demo .container .row a.btn.btn-default
	margin-top 0

@media (min-width 992px)
	nav.navbar.yamm ul.nav.navbar-nav li.dropdown.yamm-fw.guides-dropdown ul.dropdown-menu li.grid-demo .container .row ul.dropdown-menu-list.no-list.un-pd li a
		display: block
		padding 13px 0


@media (min-width 768px) and (max-width 991px)
	.navbar-brand>img
		width: 180px

	.navbar-nav > li > a
		font-size: 12px


.page-template-guides_page_archive .blog-lists-wrapper h3
	font-weight 600
	margin 0 auto
	font-size 23px

.page-template-guides_page_archive .blog-lists-wrapper .media + h3
	padding-top 3rem



// Home Blog Widget Styling
.lates-post-wrapper.custom-post-style
	padding: 20px 0 40px

.lates-post-wrapper.featured-post-wrapper
	padding: 60px 0 50px
	background-color: #fcfcfc

	.post-image-2,
	.post-image-3,
	.post-image-4
		height: 140px

	.post-image-1
		height: 450px

	.blog-post-2
		margin-bottom: 15px
	.post-image-2.post-image
		box-shadow: inset 0 -55px 60px 0 rgba(0,0,0,0.6)
	.headline-wrapper .title-box
		background: none
		border: none
		padding: 0

	.blog-post-1
		padding-right: 0

	.headline-wrapper .title-box p
		font-size: 18px
		color: #fff

	.headline-wrapper .title-box small
		background: #8cc640
		color: #fff
		padding: 5px 10px
		margin-bottom: 10px
		display: inline-block

	.post-image-1:before
		z-index: -1

	.post-image-2:before, .post-image-3:before, .post-image-4:before
		opacity: 0


@media screen and (max-width:768px)
	.lates-post-wrapper.featured-post-wrapper .blog-post-1
		padding-right: 15px
		margin-bottom: 20px



// 404 page Styles

.top-404-image
	background: linear-gradient(to right,#35869a 0%,#4094a9 100%)

.top-404-image h5
	font-size: 18px
	color: #fff
.cta-404-content
	.container
		padding: 0 200px
.top-404-image
	padding: 60px 0
.cta-404-content
	padding: 60px 0 0
.form-box
	padding: 30px 30px 20px 30px
	min-height: 345px
	background-color: #f2f2f2
	margin-bottom: 20px
	h5
		font-size: 18px
		margin-bottom: 20px
	a
		color: #343434
	a:hover
		color: #71be59
		text-decoration: none
	.btn2
		border-radius: 6px
		margin-top: 14px
	#sidebar-form
		padding: 0
		background: none
		width: auto
		.quote-select-code
			background-image: url("https://asset.alternativemedia.com.au/lic/images/down-icon.png") !important
			background-repeat: no-repeat
			background-position: right center  !important
		.form-control
			padding: 15px 15px 15px 15px !important
			height: 50px
			border: 1px solid #e5e5e5
			margin-bottom: 20px
		#postcode
			background: #fff !important

		.btn-success
			background-color: #ff7035
			border-color: #ff7035
			border-radius: 4px
			font-family: 'Nunito Sans', 'Proxima Nova','Helvetica Neue',Helvetica,Arial,sans-serif;;
			font-size: 14px
			margin-top: 30px
			img
				margin-left: 5px
				margin-top: 4px

		.btn-success:hover
			background-color: #e94d0d


.form-list
	padding-left: 25px
	li
		list-style-type: none
		margin-bottom: 10px
	img
		margin-right: 10px
	form
		margin-top: 20px
		position: relative
	.search-btn
		background: none
		border: none
		position: absolute
		left: 0
		top: 18px
		left: 8px
	input[type="search"]
		padding: 15px 15px 15px 45px
		border: 1px solid #e5e5e5
		border-radius: 4px
		width: 95%
.vid-wrap
	margin-top: 20px

@media screen and (max-width: 1200px) {
	.cta-404-content .container {
			padding: 0 100px;
	}
}
@media screen and (max-width: 768px) {
	.cta-404-content .container {
			padding: 0 20px;
	}
	#sidebar-form {
		margin-top: 0
	}
}
@media screen and (max-width: 480px) {
 	.form-list {
 		padding: 0
 	}
	.form-box {
			padding: 15px
			min-height: auto
	}
	.form-list li {
			font-size: 13px
	}
}


// menu styling update
@media (min-width 768px) and (max-width 1199px)
	#navbar.navbar-collapse
		position: absolute
		top 100%
		left 0
		width: 100%
		bottom: auto
		background: #fff
		border-top 1px solid rgba(0,0,0,.05)
		z-index: 99

		&.collapse
			display: none !important

			&.in
				display: block !important

		&.navbar-collapse__co-brand
			display: flex !important
			width: auto
			border: 0

		a.contact-wrapper
			display: block
			position: absolute
			right 20px
			width: 172px

		.nav>li
			position static

			& > a
				padding: 20px 8px

		.navbar-right
			float left !important
			left: 0

		#menu_searchform
			position: absolute
			right 190px
			display: block

	.navbar.yamm.navbar-light
		.navbar-toggle
			display: block
			position: absolute
			right: 10px


@media (max-width 767px)
	#navbar.navbar-collapse
		position: relative

		a.contact-wrapper
			display: none
			position: absolute
			width: 172px
			margin: 0 0 0 -86px
			left 50%
			bottom: 0
			top auto

		&.in
			padding-bottom: 65px
			a.contact-wrapper
				display: block

		&.navbar-collapse__co-brand
			display: flex !important
			width: auto
			border: 0

.review-page-content .col-lg-1,
.review-page-content .col-lg-10,
.review-page-content .col-lg-11,
.review-page-content .col-lg-12,
.review-page-content .col-lg-2,
.review-page-content .col-lg-3,
.review-page-content .col-lg-4,
.review-page-content .col-lg-5,
.review-page-content .col-lg-6,
.review-page-content .col-lg-7,
.review-page-content .col-lg-8,
.review-page-content .col-lg-9,
.review-page-content .col-md-1,
.review-page-content .col-md-10,
.review-page-content .col-md-11,
.review-page-content .col-md-12,
.review-page-content .col-md-2,
.review-page-content .col-md-3,
.review-page-content .col-md-4,
.review-page-content .col-md-5,
.review-page-content .col-md-6,
.review-page-content .col-md-7,
.review-page-content .col-md-8,
.review-page-content .col-md-9,
.review-page-content .col-sm-1,
.review-page-content .col-sm-10,
.review-page-content .col-sm-11,
.review-page-content .col-sm-12,
.review-page-content .col-sm-2,
.review-page-content .col-sm-3,
.review-page-content .col-sm-4,
.review-page-content .col-sm-5,
.review-page-content .col-sm-6,
.review-page-content .col-sm-7,
.review-page-content .col-sm-8,
.review-page-content .col-sm-9,
.review-page-content .col-xs-1,
.review-page-content .col-xs-10,
.review-page-content .col-xs-11,
.review-page-content .col-xs-12,
.review-page-content .col-xs-2,
.review-page-content .col-xs-3,
.review-page-content .col-xs-4,
.review-page-content .col-xs-5,
.review-page-content .col-xs-6,
.review-page-content .col-xs-7,
.review-page-content .col-xs-8,
.review-page-content .col-xs-9
	padding-right 10px
	padding-left 10px
	-webkit-transition 0s ease
	-moz-transition 0s ease
	-ms-transition 0s ease
	-o-transition 0s ease
	transition 0s ease

.review-page-content
	.row
		margin-left -10px
		margin-right -10px
	*
		-webkit-transform translate3d(0, 0, 0)
		-webkit-transition .5s ease
		-moz-transition .5s ease
		-ms-transition .5s ease
		-o-transition .5s ease
		transition .5s ease
	header
		position relative
		padding 50px 20px
		background url(https://asset.healthinsurancecomparison.com.au/images/review-header-bg.png) top center no-repeat #459bb0
		margin-bottom 40px
		*
			padding 0
			margin 0
		h1
			text-transform uppercase
			font-weight 400
			margin 10px 0 0
			font-size 40px
			font-family inherit
		p
			font-size 20px
		.header-shadow
			position absolute
			top 100%
			left 0
			right 0
			width 100%
			max-width 950px
			display block
			margin 1px auto 0
	h3
		font-size 24px

.light
	font-weight 300

.review-page-content .sitetitle,
.review-page-content h1,
.review-page-content h2,
.review-page-content h3,
.review-page-content h4,
.review-page-content h5,
.review-page-content h6
	margin 0 0 20px
	font-family inherit
	font-weight 400

.our-score
	display block
	padding 15px
	background: #ededed
	padding: 30px
	text-align left
	margin-bottom 20px
	h5
		font-size 18px
		font-weight 700
		margin 0 0 5px
		text-transform uppercase

.star-points,
.the-score
	display block

.the-score
	font-weight 300
	color #313131
	font-size 70px
	line-height 1
	&:after
		content '/5'
		color #313131
		font-size 36px
		vertical-align top
		display inline-block
		position relative
		top 10px

.star-points
	width 165px
	position relative
	height 45px

.star-after,
.star-before
	font-style normal
	position absolute
	top 0
	left 0
	overflow hidden
	display block
	white-space nowrap

.star-after:before,
.star-before:before
	content "\f005\f005\f005\f005\f005"
	display inline-block
	font-family FontAwesome
	font-size 30px
	color #bebebe
	letter-spacing 5px
	overflow hidden

.star-after
	z-index 3
	color #ffdd3d
	&:before
		color #f8dc0e

.star-before
	z-index 2

.feefo-logo
	display: block
	margin: 20px 0 5px
	font-size: 13px
	img
		position relative
		top -4px
		margin-left 5px

.read-more
	display inline-block
	zoom 1
	min-width 70px
	padding 8px 10px
	border-radius 4px
	background #71be59
	color #fff
	margin-bottom 10px
	font-weight 700
	cursor pointer

.read-more:focus,
.read-more:hover
	background #334550

.the-review
	display block
	border 1px solid #ececec
	border-radius: 10px
	-webkit-box-shadow 0 1px 1px 0 rgba(0, 0, 0, .1)
	-moz-box-shadow 0 1px 1px 0 rgba(0, 0, 0, .1)
	box-shadow 0 1px 1px 0 rgba(0, 0, 0, .1)
	margin-bottom 20px
	.text-right
		padding 0
		margin 0
	strong
		font-size 16px

.the-review span,
.the-review strong
	display block
	margin-bottom 12px
	line-height 1

.review-date
	color #bebebe

.review-rating
	color #fff
	padding: 10px 20px
	background-color: #36879b
	display: inline-block

.share-link
	display inline-block
	zoom 1
	padding 4px 7px
	border-radius 4px
	background #bebebe
	color #fff
	font-weight 700
	cursor pointer
	vertical-align middle
	line-height 16px
	font-size 12px
	i
		font-size 16px
		font-weight 400

.the-review:focus,
.the-review:hover
	border: 1px solid #3A8DA1

.share-link:focus,
.share-link:hover,
.the-review:focus .share-link,
.the-review:hover .share-link
	background #3b5998

.rating_Bad,
.rating_Very_Bad
	color #b10808


@media (max-width: 767px)
	.review-page-content .col-lg-1,
	.review-page-content .col-lg-10,
	.review-page-content .col-lg-11,
	.review-page-content .col-lg-12,
	.review-page-content .col-lg-2,
	.review-page-content .col-lg-3,
	.review-page-content .col-lg-4,
	.review-page-content .col-lg-5,
	.review-page-content .col-lg-6,
	.review-page-content .col-lg-7,
	.review-page-content .col-lg-8,
	.review-page-content .col-lg-9,
	.review-page-content .col-md-1,
	.review-page-content .col-md-10,
	.review-page-content .col-md-11,
	.review-page-content .col-md-12,
	.review-page-content .col-md-2,
	.review-page-content .col-md-3,
	.review-page-content .col-md-4,
	.review-page-content .col-md-5,
	.review-page-content .col-md-6,
	.review-page-content .col-md-7,
	.review-page-content .col-md-8,
	.review-page-content .col-md-9,
	.review-page-content .col-sm-1,
	.review-page-content .col-sm-10,
	.review-page-content .col-sm-11,
	.review-page-content .col-sm-12,
	.review-page-content .col-sm-2,
	.review-page-content .col-sm-3,
	.review-page-content .col-sm-4,
	.review-page-content .col-sm-5,
	.review-page-content .col-sm-6,
	.review-page-content .col-sm-7,
	.review-page-content .col-sm-8,
	.review-page-content .col-sm-9,
	.review-page-content .col-xs-1,
	.review-page-content .col-xs-10,
	.review-page-content .col-xs-11,
	.review-page-content .col-xs-12,
	.review-page-content .col-xs-2,
	.review-page-content .col-xs-3,
	.review-page-content .col-xs-4,
	.review-page-content .col-xs-5,
	.review-page-content .col-xs-6,
	.review-page-content .col-xs-7,
	.review-page-content .col-xs-8,
	.review-page-content .col-xs-9
		width 100%

@media (max-width: 767px)
	.container
		&.review-page-content
			margin-top 0




// REVIEWS CUSTOM STYLE

.reviews-header.jumbotron
	padding: 60px

.reviews-header
	h1
		font-weight 700
	.review-line
		width: 50px
		border: 1px solid #ffb10b
		margin: 30px auto 40px
		display: block

.top-review
	padding: 45px 0 30px
	p
		margin-bottom: 40px
		line-height: 24px
	.col-md-8.col-sm-6
		padding: 0 30px 0 10px

.review-award
	margin-bottom: 35px

.review-wrapper
	padding: 40px
	background-color: #fff
	-webkit-box-shadow: 0px 0px 44px -12px rgba(226,226,226,1)
	-moz-box-shadow: 0px 0px 44px -12px rgba(226,226,226,1)
	box-shadow: 0px 0px 44px -12px rgba(226,226,226,1)
	.read-more
		padding: 15px 40px
		img
			margin-left 10px
		&:hover
			background-color: #4A4645
			color: #fff
			text-decoration: none
	.compare__cta-btn
		background-color: #e67e22
		text-align: right
	h3
		font-weight: bold
	.col-md-4
		margin-bottom: 20px

.wrap-review
	padding: 30px 30px 0

.read-more-review
	padding: 10px
	text-align: center
	background-color: #c6c6c6
	border-radius: 0px 0 4px 4px
	&:hover
		background-color: #ff6600
	a
		color: #fff
		display: block
		text-decoration: none

.read-more-view
	display: block
	width: 200px
	padding: 15px
	color: #fff
	border-radius: 4px
	background-color: #71be59
	cursor: pointer
	text-align: center
	margin: 0 auto
	&:hover
		background-color: #4a4645
		text-decoration: none
		color: #fff
	img
		margin-left: 5px

.read-click-wrap
	background-color: #fff
	padding: 30px 0 40px
	-webkit-box-shadow: 0px 0px 35px -12px rgba(226,226,226,1)
	-moz-box-shadow: 0px 0px 35px -12px rgba(226,226,226,1)
	box-shadow: 0px 0px 35px -12px rgba(226,226,226,1)
	&:after
		content: ''
		background-image: linear-gradient(rgba(255, 255, 255, 0), #fff)
		position: absolute
		height: 55px
		left: 0
		right: 0
		top: -50px
		display: block
		width: 100%
		background-size: 100%
		z-index: 10
.hide
	display: none
@media (max-width: 1199px)
	.the-score
		margin-top: 15px
	.review-wrapper
		.read-more
			padding: 15px 20px
			
@media (max-width: 991px)
	.review-wrapper
		.read-more
			width: 100%
		.compare__cta-btn
			text-align: left

@media (max-width: 767px)
	.review-award
		margin-top: 40px

@media (max-width: 480px)
	.review-wrapper 
		.read-more
			font-size: 12px
			padding: 15px
			img
				float: right
				margin: 0
		

// Team Page Custom Style
.team-content-wrapper
	background-color: #fff
.hero-headline.hero-headline2
	padding: 65px 0



// hero slider
@import 'sections/slick'

.hero-slider-section
	background: #4094a9
	background: linear-gradient(to right, #35869a 0%, #4094a9 100%)
	padding: 0
	position: relative

	.hero-slider
		opacity: 0
		transition: 1s

		&.slick-initialized
			opacity: 1

		a
			&:focus
				outline 0
			img
				display: block
				width: 100%
				height: auto

				&.mobile_hero_img
					display: none

			&.mobile_hero_slide

				@media (max-width 767px)
					img
						display: none

						&.mobile_hero_img
							display: block

		.slick-dots
			position: absolute
			left: 0
			bottom 11px
			width: 100%
			list-style: none
			margin: 0
			padding: 0
			text-align: center
			z-index: 10

			li
				display: inline-block

				button
					width: 10px
					height: 10px
					border 0
					outline 0
					padding: 0
					background: #fff
					border-radius: 500px
					text-indent 99px
					overflow: hidden
					opacity: .25
					margin: 0 4px

				&.slick-active
					button
						opacity: 1

.hero-cta-section
	background: #f7f7f7
	padding 50px 0

	@media (max-width 1200px)
		padding: 30px 0 40px

	h1
		font-size: 38px
		color #398ba0
		text-align: center
		margin: 0 0 25px

		strong
			font-weight 700
			font-weight: normal

		@media (max-width 1200px)
			font-size: 32px

		@media (max-width 767px)
			width: 80%
			margin: 0 auto 20px
			font-size: 28px

	.fld-wrap
		position: relative

		@media (max-width 767px)
			margin-bottom: 10px

		.questions-tooltip
			position: absolute
			z-index: 10
			right 9px
			top 9px
			opacity: .3

	input
	select
		&.form-control
			border 1px solid #dbdbdb
			font-size: 18px
			height: 52px
			color #313131
			padding: 12px 15px
			position: relative

			@media (max-width 1200px)
				font-size: 14px

	input
		&::-webkit-input-placeholder
			color #313131
		&::-moz-placeholder
			color #313131
		&:-ms-input-placeholder
			color #313131
		&:-moz-placeholder
			color #313131

	select
		appearance none
		text-indent: 1px
		text-overflow: ''
		background: #fff url(https://asset.healthinsurancecomparison.com.au/wp-content/uploads/2019/01/20122855/hic-drop-arrow.png) no-repeat 95% 50%

		&::-ms-expand
			display: none

	button
		border 0
		background: #71be59
		color #fff
		font-weight 700
		box-shadow: 0 4px 0 0 #6cb356
		padding: 11px 15px
		position: relative;
		top 0
		transition: .5s ease-in-out
		outline 0 !important
		border-radius: 4px

		&:hover
			background: lighten(#71be59, 15%)

		&:focus
		&:active
			top 4px
			box-shadow: 0 0 0 0 darken(#6cb356, 10%)
			background: #6cb356


// Custom Archive Styling (tpl-customarchive.php)
body.page-template-tpl-customarchive
	#inner-page
		.inner-main-title
			margin-bottom: 30px
		.intro-content
			padding-bottom: 20px
			margin-bottom: 20px
			border-bottom: 1px solid #cdcdcd
			p
				margin-bottom: 20px
				color #444444
				font-size: 16px

		.main-blog-post

			.main-blog-excerpt
				font-size: 16px
				color #727272

	footer
		.container.list-footer
			@media (min-width 1200px)
				width 975px
				max-width: 100%

		.extra_footer_content
			padding-bottom: 10px
			margin-bottom: 30px
			border-bottom: 1px solid #ebebeb
			line-height: 1.6

			p
				font-size: 14px
				color #888888

			h6
				font-size: 16px
				color #636363
				font-weight: normal;
				font-weight 700
				margin: 0 0 15px

// GUIDE TOC CUSTOM STYLE
#toc__widget-sidebar
	.static__page-content
		position: relative !important	
	#sidebar-form
		display: block
		width: 248px
		margin: 0

// PPC Mega menu custom style 
.page-template-tpl-ppc
	.intro-header
		padding: 0
	.yamm
		min-height: 60px
		margin-bottom: 0
		
		.container 
			max-width: 1280px
			width: auto
		.btn-lg
			padding: 10px 16px
			box-shadow: none
		.btn-default
			display: inline-block
			width: auto 
			box-shadow: none
		.req-call-link
			font-family: 'Proxima Nova Bold'
	.navbar-brand
		padding: 7px 
	.navbar-nav > li > a
		padding: 25px
		&:hover 
			background: none
	#navbar a.contact-wrapper
		@media (max-width: 1199px)
			width: 177px


.navbar.header__co-brand 
	position: relative
	@media (max-width: 1199px)
		min-height: 64px
	.container
		position: static
		max-width: 1280px
		width: auto
		padding: 0 20px
		@media (max-width: 767px)
			padding-left: 16px
			padding-right: 16px

	.navbar-header
		@media (min-width 768px) and (max-width 992px)
			width: 100%
		@media (max-width: 767px)
			margin: 0
		@media (min-width 768px) and (max-width 1024px) and (orientation: landscape)
			width: 100%
		@media (min-width 1024px) and (max-width 1366px) and (orientation: portrait)
			width: 100%

	#navbar.navbar-collapse.collapse.in
		@media (min-width: 768px) and (max-width: 1199px)
			position static
			padding: 0
	.navbar-brand 
		display flex 
		align-items: center
		margin-top: 0
		height: auto
		padding: 0
		.navbar-brand__co-brand
			width: 340px
			height: auto
			@media (min-width: 768px) and (max-width: 1023px) and (orientation: portrait)
				width: 253px
				margin: 3px 0px
			@media (min-width: 992px) and (max-width: 1199px)
				width: 193px
			@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
				width: 253px
				margin: 5px 0px
			@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait)
				width: 253px
				margin: 3px 0px
			@media (max-width: 767px)
				width: auto !important
				height: 45px
				margin-top: 10px
				margin-left: -8px

	.navbar-brand__cc
		position relative
		display inline-block
		padding-top 13px
		padding-bottom 9px
		margin-left 15px 
		padding-left 15px 
		border-left 1px solid #C5C5C5
		b 
			font-size 11.25px 
			position absolute
			left 48px
			font-weight 600
			top 3px
			color #193560
			font-family 'Source Sans Pro',sans-serif
			@media (max-width 992px)
				font-size 8px
			@media (min-width 768px) and (max-width 992px)
				left 38px
			@media (max-width 767.98px)
				left 30px
		img
			width 183px
			@media (min-width 768px) and (max-width 992px)
				width 127px 
			@media (max-width 767.98px)
				width 85px

	.navbar-right
		width: auto
		@media (max-width: 767px)
			text-align: left !important;
			margin: 0 !important;
			width: auto !important
		&.contact-wrapper
			padding 21px 0;
			margin-top: 0;
			@media (max-width: 767px)
				left: 25px !important;
			&__co-brand
				display: block !important
				border-top: 0 !important
				@media (max-width: 767px)
					position: relative !important;
					right: 16px;
					display: flex !important;
				@media (max-width: 1199px)
					bottom: 0 !important;
					padding-top: 16px;
					padding-bottom: 16px;
			.contact-talk
				border-left: 1px solid #D2D2D2
				font-size: 24px
				line-height: 24px
				font-weight: 700
				text-align: right
				color: #193560
				padding-left: 16px
				margin-left: 16px
				letter-spacing: normal !important
				@media (max-width: 767px)
					display: none
				small
					font-size: 12px
					line-height: 12px
					color: #193560
					font-weight: 700
					text-align: right !important
		svg
			height: 32px
			width: 32px
			margin-left: 20px
			@media (max-width: 767px)
				margin-left: 0
	
	.navbar-nav.navbar-nav-v2 > li > a,
	.navbar-nav > li > a
		padding: 30px 20px !important
		color: #193560
		@media(max-width: 767px)
			padding: 20px 10px !important
			font-size: 14px !important

	.navbar-collapse
		&.in
			@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
				border-top: 0 !important
			@media (min-width 768px) and (max-width 992px)
				border-top: 0 !important
			.navbar-right
				width: 100%
		.navbar-right
			right: 0
			text-align: right
			img
				float: none
			@media(max-width: 1200px)
				position: relative
			@media (min-width 768px) and (max-width 992px)
				width: auto;
				position: absolute;
				bottom: 4px;
				right: 20px;
			@media (min-width 1024px) and (max-width 1366px) and (orientation: portrait)
				bottom: 4px;
				right: 20px;
				position: absolute;
				&.contact-wrapper
					border-top: 1px solid rgba(0,0,0,0.05);
					position: relative !important;
					right: 0  !important;
					width: 100%  !important;
					text-align: right;
			@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
				bottom: 4px;
				right: 20px;
				position: absolute;
				&.contact-wrapper
					border-top: 1px solid rgba(0,0,0,0.05);
					position: relative !important;
					right: 0  !important;
					width: 100%  !important;
					text-align: right;

	.navbar-header__co-brand
		display: flex
		align-items: center
		right: 0;
		top: 16px;
		float: right;
		position: relative !important;
		@media (min-width: 992px)
			display: none
		@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
			display: flex
		@media (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait)
			display: flex
		&-label 
			color: #193560
			font-weight: 700
			font-size: 14px
			line-height: 20px
			margin-right: 10px

.header__co-brand__clean
	.navbar-header 
		width: auto !important
		@media (max-width: 767px)
			float: left;
	.navbar-collapse__co-brand
		@media (max-width: 767px)
			padding: 0 24px 0 0  !important;
			justify-content: flex-end;
		@media (min-width: 768px) and (max-width: 1199px)
			display: flex !important
			