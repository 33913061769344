body.bg-white
	background-color: #ffffff !Important

.jumbotron
	background: linear-gradient(to right, rgba(53,134,154,1) 0%,rgba(64,148,169,1) 100%)
	/* IE6-9 */
	display: block
	color: #ffffff
	margin-bottom: 0

.jumbotron h1
	font-size: 45px
	margin-top: 0px

.jumbotron h1 strong
	font-weight 700

.jumbotron p
	color: #ffffff
	font-size: 20px
	line-height: 30px

.jumbotron p strong
	font-weight 700

.jumbotron .titlehead-divide
	background-color: #71be59
	height: 6px
	display: block
	margin: 25px 0px
	border-radius: 25px
	width: 20%

.jumbotron form input.form-control
	border: 0px
	border-radius: 3px
	height: 55px
	padding: 10px 16px 10px 50px
	background: #ffffff url("https://asset.healthinsurancecomparison.com.au/images/icons/postcode-location.png") no-repeat left 2rem center

.jumbotron form input.form-control::placeholder
	color: #000000

.jumbotron form button.btn
	border: 0px
	padding: 14px 16px
	border-radius: 3px
	font-size: 20px
	font-weight 600
	-webkit-box-shadow: 0px 5px 0px 0px rgba(92,163,101,1)
	-moz-box-shadow: 0px 5px 0px 0px rgba(92,163,101,1)
	box-shadow: 0px 5px 0px 0px rgba(92,163,101,1)

.jumbotron form button.btn:hover,
.jumbotron form button.btn:focus
	-webkit-box-shadow: 0px 5px 0px 0px rgba(40,121,50,1)
	-moz-box-shadow: 0px 5px 0px 0px rgba(40,121,50,1)
	box-shadow: 0px 5px 0px 0px rgba(40,121,50,1)

.jumbotron form .form-group
	position: relative
	width 100%

.jumbotron form a.questions-tooltip
	position: absolute
	right: 15px
	top: 15px
	opacity: 0.3

.jumbotron form a.questions-tooltip:hover,
.jumbotron form a.questions-tooltip:focus
	opacity: 1

.jumbotron center
	display: inline-block
	width: 100%
	margin-top: 25px

.service-works-wrapper
	margin-bottom: 70px
	padding: 0px
	background-color: #fff
	padding-top: 30px

.service-works-wrapper h2
	color: #398ba0
	font-size: 35px
	text-align: center
	display: block
	margin-bottom: 40px

.service-works-wrapper h2 strong
	font-weight 700

.service-works-wrapper img
	padding: 15px 0px

.service-works-wrapper h4
	color: #707070
	font-weight 600

.service-works-wrapper p
	color: #707070

.rating-wrapper
	background: #6eba58 url("https://asset.healthinsurancecomparison.com.au/images/geometric.jpg") repeat top center
	border-radius: 5px
	color: #ffffff
	position: relative
	padding: 35px 25px
	margin: 15px 0px 0px 0px

.rating-wrapper .rating-star
	color: #ffffff
	text-align: center

.rating-wrapper .rating-star h4
	color: #ffffff
	font-weight 300
	font-size: 20px
	margin: 0px

.rating-wrapper .rating-star h4 strong
	font-weight 700

.rating-wrapper .rating-star h1
	margin-top: 0px
	color: #ffffff
	font-weight 300
	font-size: 85px
	margin-bottom: 0px

.rating-wrapper .rating-star h1 sup
	font-weight 300
	font-size: 35px
	top: -0.9em

.rating-wrapper .rating-star img
	padding: 0px

.rating-wrapper .carousel .carousel-inner .item h4,
.rating-wrapper .carousel .carousel-inner .item p
	color: #ffffff

.rating-wrapper .carousel .carousel-inner .item p
	color: #ffffff
	font-size: 16px
	line-height: 23px

.rating-wrapper .carousel .carousel-inner .item h4
	font-size: 20px

.rating-wrapper .carousel ol.carousel-indicators
	bottom: -40px

.rating-wrapper .carousel ol.carousel-indicators li.active
	background-color: #efce4a
	border: none
	margin: 0 2px

.rating-wrapper .carousel ol.carousel-indicators li
	background-color: #ffffff
	border: none
	margin: 1px 2px

.benefits-wrapper
	margin: 0px
	padding: 30px 0px
	text-align: center
	background-color: #fcfcfc
	border-top: 1px solid #eeeeee

.benefits-wrapper h2
	color: #398ba0
	font-size: 35px
	text-align: center
	display: block
	margin-bottom: 20px

.benefits-wrapper h2 strong
	font-weight 700

.benefits-wrapper h6
	font-size: 18px
	color: #888888
	margin-bottom: 25px

.benefits-wrapper .benefit-tag
	margin: 25px 0px

.benefits-wrapper .benefit-tag h4
	color: #707070
	font-weight 600

.benefits-wrapper .benefit-tag p
	color: #707070
	font-size: 14px
	line-height: 23px

.benefits-wrapper .benefit-tag img
	margin-bottom: 10px

.user-testimonials-wrapper
	background: #52a8bd
	background: linear-gradient(to right, rgba(82,168,189,1) 0%,rgba(54,134,156,1) 100%)
	display: block
	color: #ffffff
	position: relative
	padding: 25px 15px 75px 15px

.user-testimonials-wrapper h2
	color: #ffffff
	font-size: 45px
	text-align: center
	display: block
	font-weight 700
	margin-bottom: 30px

.user-testimonials-wrapper .carousel .carousel-inner .item
	background-color: #ffffff
	border-radius: 5px
	-webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3)
	-moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3)
	box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3)

.user-testimonials-wrapper .carousel .carousel-inner .item p
	color: #888888
	font-size: 16px
	font-style: italic

.user-testimonials-wrapper .carousel .carousel-inner .item h4
	font-size: 18px
	color: #8ec64c
	font-weight 700
	text-align: right
	margin-bottom: 0px
	display: block

.user-testimonials-wrapper .carousel-control
	width: auto !important

.user-testimonials-wrapper .carousel-control img
	margin-top: 100%

.user-testimonials-wrapper .carousel-control.left,
.user-testimonials-wrapper .carousel-control.right
	background: transparent !important

.user-testimonials-wrapper .carousel ol.carousel-indicators
	bottom: -45px !important

.user-testimonials-wrapper .carousel ol.carousel-indicators li.active
	background-color: #8ec64c
	border: none
	margin: 0 2px

.user-testimonials-wrapper .carousel ol.carousel-indicators li
	background-color: #ffffff
	border: none
	margin: 1px 2px

.description-wrapper-1
	background-color: #f7f7f7

.description-wrapper-1 .desc-tag h2,
.description-wrapper-2 .desc-tag h2
	color: #398ba0
	font-size: 35px
	display: block
	margin-bottom: 25px

.description-wrapper-1 .desc-tag h2 strong,
.description-wrapper-2 .desc-tag h2 strong
	font-weight 700

.description-wrapper-1 .desc-tag p,
.description-wrapper-2 .desc-tag p
	font-size: 16px
	color: #888888
	margin-bottom: 25px

.description-wrapper-1 .desc-tag a.btn.btn-success,
.description-wrapper-2 .desc-tag a.btn.btn-success
	border-radius: 4px
	border: none
	font-weight 600
	color: #fff
	background-color: #71be59
	border-color: #71be59
	padding: 10px 25px
	-webkit-box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)
	-moz-box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)
	box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)

.description-wrapper-1 .desc-tag a.btn.btn-success:hover,
.description-wrapper-2 .desc-tag a.btn.btn-success:hover,
.description-wrapper-1 .desc-tag a.btn.btn-success:focus,
.description-wrapper-2 .desc-tag a.btn.btn-success:focus,
.description-wrapper-1 .desc-tag a.btn.btn-success.active,
.description-wrapper-2 .desc-tag a.btn.btn-success.active
	background-color: #6ab553
	border-color: #6ab553

.description-wrapper-1 .desc-tag a.btn.btn-success img,
.description-wrapper-2 .desc-tag a.btn.btn-success img
	margin-left: 10px
	margin-top: -3px
	margin-bottom: 0px
	margin-right: 0px

.lates-post-wrapper
	padding: 35px 0px 0px 0px
	position: relative

.lates-post-wrapper h2
	color: #398ba0
	font-size: 40px
	text-align: center
	display: block
	margin-bottom: 20px

.lates-post-wrapper h2 strong
	font-weight 700

.lates-post-wrapper h6
	font-size: 18px
	color: #888888
	margin-bottom: 45px
	text-align: center

.lates-post-wrapper .headline-wrapper
	position: absolute
	width: 82%
	bottom: 25px
	left: 0
	margin: 0 auto
	right: 0
	z-index: 9999

.lates-post-wrapper .headline-wrapper h6
	color: #ffffff
	font-weight 700
	text-align: left
	display: block
	margin-bottom: 5px
	font-size: 14px

.lates-post-wrapper .headline-wrapper .title-box
	background-color: #ffffff
	padding: 7px 15px
	border-top: 4px solid #71be59
	border-bottom-left-radius: 5px
	border-bottom-right-radius: 5px
	position: relative

.lates-post-wrapper .col-xs-12
	position: relative

.lates-post-wrapper .headline-wrapper .title-box small
	color: #888888
	font-size: 12px
	font-weight 700

.lates-post-wrapper .headline-wrapper .title-box p
	font-weight 700
	margin-bottom: 5px

.lates-post-wrapper .headline-wrapper .title-box a:hover,
.lates-post-wrapper .headline-wrapper .title-box a:focus
	text-decoration: none

.lates-post-wrapper .headline-wrapper .title-box a:hover p,
.lates-post-wrapper .headline-wrapper .title-box a:focus p
	color: #71be59
	text-decoration: none

.lates-post-wrapper .col-lg-4,
.lates-post-wrapper .col-lg-8
	margin-bottom: 25px

.lates-post-wrapper .headline-wrapper .title-box img
	position: absolute
	bottom: -10px

.lates-post-wrapper .wider-content .headline-wrapper
	position: absolute
	bottom: 25px
	margin: 0 auto

.view-all-button
	display: inline-block
	width: 100%
	text-align: center
	padding: 15px 0px

.view-all-button a.btn.btn-success
	border-radius: 4px
	border: none
	font-weight 600
	color: #fff
	background-color: #71be59
	border-color: #71be59
	padding: 10px 45px
	-webkit-box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)
	-moz-box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)
	box-shadow: 0px 4px 0px 0px rgba(101,170,80,1)

.view-all-button a.btn.btn-success:hover,
.view-all-button a.btn.btn-success:focus,
.view-all-button a.btn.btn-success.active
	background-color: #6ab553
	border-color: #6ab553

.view-all-button a.btn.btn-success img
	margin-left: 10px
	margin-top: -3px
	margin-bottom: 0px
	margin-right: 0px

@keyframes flickerAnimation
	0%
		opacity: 1
	50%
		opacity: 0.4
	100%
		opacity: 1
@keyframes flickerAnimation
	0%
		opacity: 1
	50%
		opacity: 0.4
	100%
		opacity: 1
@keyframes flickerAnimation
	0%
		opacity: 1
	50%
		opacity: 0.4
	100%
		opacity: 1
@keyframes flickerAnimation
	0%
		opacity: 1
	50%
		opacity: 0.4
	100%
		opacity: 1
.animate-flicker
	-webkit-animation: flickerAnimation 1.5s infinite
	-moz-animation: flickerAnimation 1.5s infinite
	-o-animation: flickerAnimation 1.5s infinite
	animation: flickerAnimation 1.5s infinite

.post-image-1
	background-position: center center !important
	background-size: cover !important
	background-repeat: no-repeat !important
	position: relative

.post-image-2
	background-position: center center !important
	background-size: cover !important
	background-repeat: no-repeat !important
	position: relative

.post-image-3
	background-position: center center !important
	background-size: cover !important
	background-repeat: no-repeat !important
	position: relative

.post-image-4
	background-position: center center !important
	background-size: cover !important
	background-repeat: no-repeat !important
	position: relative

.post-image-1:before,
.post-image-2:before,
.post-image-3:before,
.post-image-4:before
	content: ''
	position: absolute
	left: 0
	top: 0
	height: 100%
	width: 100%
	z-index: 2
	background: linear-gradient(135deg, #999999 35%, #bbbbbb 100%)
	opacity: .35

@media (min-width: 320px)
	.jumbotron .titlehead-divide
		width: 25%
		margin: 25px auto

	.jumbotron .col-lg-5
		text-align: center

	.jumbotron
		padding-top: 48px
		padding-bottom: 48px

	.testimoni-wrapper
		border-left: 0px solid #ffffff
		text-align: center
		padding: 15px 15px
		margin-top: 20px

	.jumbotron .container .col-lg-7 img
		width: 200px
		margin-top: 50px

	.jumbotron .container .col-lg-7
		text-align: center

	.service-works-wrapper .panel
		min-height: auto

	.user-testimonials-wrapper .carousel-control img
		display: none

	.user-testimonials-wrapper .carousel .carousel-inner .item
		width: 100%
		margin: 0px auto 15px auto
		padding: 15px

	.benefits-wrapper .benefit-tag p
		padding: 0px 20px

	.description-wrapper-1 .desc-tag,
	.description-wrapper-2 .desc-tag
		padding: 15px 15px 35px 15px

	.lates-post-wrapper .headline-wrapper .title-box p
		line-height: 16px
		font-size: 14px

	.lates-post-wrapper .wider-content .headline-wrapper
		left: 0
		right: 0
		width: 80%

	.post-image-1,
	.post-image-2,
	.post-image-3,
	.post-image-4
		height: 200px

@media (min-width: 480px)
	.jumbotron .titlehead-divide
		width: 25%
		margin: 25px auto

	.jumbotron .col-lg-5
		text-align: center

	.jumbotron
		padding-top: 48px
		padding-bottom: 48px

	.testimoni-wrapper
		border-left: 0px solid #ffffff
		text-align: center
		padding: 15px 15px
		margin-top: 20px

	.jumbotron .container .col-lg-7 img
		width: 350px
		margin-top: 50px

	.jumbotron .container .col-lg-7
		text-align: center

	.service-works-wrapper .panel
		min-height: auto

	.user-testimonials-wrapper .carousel-control img
		display: none

	.user-testimonials-wrapper .carousel .carousel-inner .item
		width: 100%
		margin: 0px auto 15px auto
		padding: 15px

	.benefits-wrapper .benefit-tag p
		padding: 0px 30px

	.description-wrapper-1 .desc-tag,
	.description-wrapper-2 .desc-tag
		padding: 15px 15px 35px 15px

	.lates-post-wrapper .headline-wrapper .title-box p
		line-height: 16px
		font-size: 14px

	.lates-post-wrapper .wider-content .headline-wrapper
		left: 0
		right: 0
		width: 80%

	.post-image-1,
	.post-image-2,
	.post-image-3,
	.post-image-4
		height: 200px

@media (min-width: 768px)
	.jumbotron .titlehead-divide
		width: 20%
		margin: 25px 0px

	.jumbotron .col-lg-5
		text-align: left

	.jumbotron
		padding-top: 48px
		padding-bottom: 30px

	.testimoni-wrapper
		border-left: 0px solid #ffffff
		text-align: center
		padding: 15px 15px
		margin-top: 0px

	.jumbotron .container .col-lg-7 img
		width: 500px
		margin-top: 50px

	.jumbotron .container .col-lg-7
		text-align: center

	.service-works-wrapper .panel
		min-height: 325px

	.user-testimonials-wrapper .carousel-control img
		display: inline-block

	.user-testimonials-wrapper .carousel .carousel-inner .item
		width: 85%
		margin: 0px auto 15px auto
		padding: 25px

	.benefits-wrapper .benefit-tag p
		padding: 0px 0px

	.description-wrapper-1 .desc-tag,
	.description-wrapper-2 .desc-tag
		padding: 30px 15px

	.lates-post-wrapper .headline-wrapper .title-box p
		line-height: 16px
		font-size: 12px

	.lates-post-wrapper .wider-content .headline-wrapper
		left: 5%
		right: auto
		width: 40%

	.post-image-1
		height: 500px

	.post-image-2,
	.post-image-3,
	.post-image-4
		height: 238px

@media (min-width: 992px)
	.jumbotron .titlehead-divide
		width: 20%
		margin: 25px 0px

	.jumbotron .col-lg-5
		text-align: left

	.jumbotron
		padding-top: 48px
		padding-bottom: 30px

	.testimoni-wrapper
		border-left: 1px solid #ffffff
		text-align: left
		padding: 15px 35px
		margin-top: 0px

	.jumbotron .container .col-lg-7 img
		width: 500px
		margin-top: 50px

	.jumbotron .container .col-lg-7
		text-align: right

	.service-works-wrapper .panel
		min-height: 325px

	.user-testimonials-wrapper .carousel-control img
		display: inline-block

	.user-testimonials-wrapper .carousel .carousel-inner .item
		width: 85%
		margin: 0px auto 15px auto
		padding: 25px

	.benefits-wrapper .benefit-tag p
		padding: 0px 25px

	.description-wrapper-1 .desc-tag,
	.description-wrapper-2 .desc-tag
		padding: 30px 15px

	.lates-post-wrapper .headline-wrapper .title-box p
		line-height: 18px
		font-size: 14px

	.lates-post-wrapper .wider-content .headline-wrapper
		left: 5%
		right: auto
		width: 40%

	.post-image-1
		height: 500px

	.post-image-2,
	.post-image-3,
	.post-image-4
		height: 238px

@media (min-width: 1200px)
	.jumbotron .titlehead-divide
		width: 20%
		margin: 25px 0px

	.jumbotron .col-lg-5
		text-align: left

	.jumbotron
		padding-top: 48px
		padding-bottom: 30px

	.testimoni-wrapper
		border-left: 1px solid #ffffff
		text-align: left
		padding: 15px 35px
		margin-top: 0px

	.jumbotron .container .col-lg-7 img
		width: 580px
		margin-top: 15px

	.jumbotron .container .col-lg-7
		text-align: right

	.service-works-wrapper .panel
		min-height: 325px

	.user-testimonials-wrapper .carousel-control img
		display: inline-block

	.user-testimonials-wrapper .carousel .carousel-inner .item
		width: 85%
		margin: 0px auto 15px auto
		padding: 25px

	.benefits-wrapper .benefit-tag p
		padding: 0px 25px

	.description-wrapper-1 .desc-tag,
	.description-wrapper-2 .desc-tag
		padding: 70px 15px

	.lates-post-wrapper .headline-wrapper .title-box p
		line-height: 18px
		font-size: 14px

	.lates-post-wrapper .wider-content .headline-wrapper
		left: 5%
		right: auto
		width: 40%

	.post-image-1
		height: 500px

	.post-image-2,
	.post-image-3,
	.post-image-4
		height: 238px



.post-tag-wrapper
	.post-image
		transition: .5s ease-in-out
		transform: scale(1)
	a
		overflow: hidden
		display: block

		&:hover
		&:focus
			.post-image
				transform: scale(1.5)
