.article-content .blog-wrapper a
	font-family inherit
	font-weight 600
	text-decoration: underline

.article-content .blog-wrapper .title-article h2
	color: #22849d
	font-family inherit
	font-weight 600

.divide
	background: linear-gradient(to left, #5dc8d8, #11738e )
	height: 5px
	display: block
	margin: 12px 0px 25px 0px

.article-content h3.blog-category-heading
	color: #22849d
	font-family: inherit
	font-weight 600
	line-height: 1.1
	font-size: 20px
	letter-spacing: 0.5px
	margin-bottom: 0px

.article-content .blog-wrapper .media .media-body h4.media-heading
	color: #22849d
	font-family: inherit
	font-weight 600
	line-height: 25px


.article-content .blog-wrapper .media .media-body a.btn.btn-success
	border-radius: 35px
	text-decoration: none
	font-family: inherit
	font-weight normal
	padding: 6px 20px

.article-content .blog-wrapper .media .media-body a.btn.btn-success img
	margin-left: 5px
	margin-top: -2px

.article-content .category-wrapper hr
	border-width: 2px

.article-content .category-wrapper .panel .panel-body ul
	list-style-type: none
	margin: 0px
	padding: 0px 15px

.article-content .category-wrapper .panel .panel-body ul li
	list-style-type: none
	background: url("https://asset.healthinsurancecomparison.com.au/images/icons/blue_arrow.png") no-repeat center left
	font-size: 14px
	padding-left: 20px
	padding-top: 4px
	padding-bottom: 4px
	padding-right: 0px

.article-content .category-wrapper .panel .panel-body ul li a
	font-family: inherit
	font-weight 600
	color: #000000

.article-content .category-wrapper .panel .panel-body ul li a:hover,
.article-content .category-wrapper .panel .panel-body ul li.active a,
.article-content .category-wrapper .panel .panel-body ul li a:focus
	color: #22849d
	text-decoration: none

.blog-lists-wrapper
	display: block
	margin: 35px 0px

@media (min-width: 320px)
	.divide
		width: 100%

	// .article-content .blog-wrapper .media .media-left img
	//   width: 130px

	.article-content .blog-wrapper .media .media-left
		padding-right: 15px

@media (min-width: 480px)
	.divide
		width: 100%

	// .article-content .blog-wrapper .media .media-left img
	//   width: 150px

	.article-content .blog-wrapper .media .media-left
		padding-right: 15px

@media (min-width: 768px)
	.divide
		width: 100px

	// .article-content .blog-wrapper .media .media-left img
	//   width: 180px

	.article-content .blog-wrapper .media .media-left
		padding-right: 15px

@media (min-width: 992px)
	.divide
		width: 100px

	// .article-content .blog-wrapper .media .media-left img
	//   width: 210px

	.article-content .blog-wrapper .media .media-left
		padding-right: 25px

@media (min-width: 1200px)
	.divide
		width: 100px

	// .article-content .blog-wrapper .media .media-left img
	//   width: 250px

	.article-content .blog-wrapper .media .media-left
		padding-right: 25px


// blogform layout fix
.article-content .blog-wrapper #lead-form
		.media
				margin 0
				.media-left img
						width: 35px
