.article-content-wrapper .table-calculator-wrapper .panel .panel-body .rebate-result-wrapper.text-left
    border: 1px solid #eeeeee
    background-color: #f8f8f8
    padding: 30px

@media (min-width: 320px)
  .panel .panel-body.rebate-wrapper .form-group label span
    float: none
    display: block
    width: 120px
    margin: 5px auto
    text-align: center

@media (min-width: 480px)
  .panel .panel-body.rebate-wrapper .form-group label span
    float: none
    display: block
    width: 120px
    margin: 5px auto

@media (min-width: 768px)
  .panel .panel-body.rebate-wrapper .form-group label span
    float: right
    display: inline-block
    width: auto
    margin: 0px 0px
    text-align: center

@media (min-width: 992px)
  .panel .panel-body.rebate-wrapper .form-group label span
    float: right
    display: inline-block
    width: auto
    margin: 0px 0px
    text-align: center

@media (min-width: 1200px)
  .panel .panel-body.rebate-wrapper .form-group label span
    float: right
    display: inline-block
    width: auto
    margin: 0px 0px
    text-align: center


.calc_page .rebate-wrapper
    h4, hr
        display: none

.calc_page.about-content-wrapper .article-content .blog-wrapper .title-article h2
    color #343434
