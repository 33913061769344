.well.search-wrapper
  background: linear-gradient(to right, rgba(17,115,142,1) 0%,rgba(93,200,216,1) 100%)
  border: none
  margin-top: 15px

.well.compare-quotes-wrapper
  background: linear-gradient(to right, rgba(17,115,142,1) 0%,rgba(93,200,216,1) 100%)
  border: none

.well.compare-quotes-wrapper.btm-mgn
  margin-bottom: 35px

.well.search-wrapper h4
  margin-top: 0px
  color: #ffffff
  font-weight 600

.well.compare-quotes-wrapper h3
  margin-top: 0px
  color: #ffffff
  font-weight 400
  margin-bottom: 15px

.well.compare-quotes-wrapper input.form-control
  border: none
  background: #ffffff url("https://asset.healthinsurancecomparison.com.au/images/icons/suburb.png") no-repeat center left 0.8rem
  padding: 6px 12px 6px 28px
  font-weight 600

.well.compare-quotes-wrapper input.form-control::placeholder
  color: #555

.well.compare-quotes-wrapper h3.strong
  font-weight 700

.well.compare-quotes-wrapper .form-group .btn-group .dropdown-menu
  max-height: 200px !important
  overflow: visible !important

.well.compare-quotes-wrapper .form-group .btn-group .dropdown-menu
  max-height: 200px !important
  overflow: visible !important

.well.compare-quotes-wrapper .form-group .bootstrap-select.btn-group .dropdown-toggle .filter-option
  padding-top: 2px

.well.search-wrapper .input-group input.form-control
  border: none
  border-top-left-radius: 35px
  border-bottom-left-radius: 35px

.well.search-wrapper .input-group span.input-group-btn button.btn
  border-color: #ffffff
  border-top-right-radius: 35px
  border-bottom-right-radius: 35px
  padding: 3.5px 5px

.well.search-wrapper .input-group span.input-group-btn button.btn:hover,
.well.search-wrapper .input-group span.input-group-btn button.btn:focus,
.well.search-wrapper .input-group span.input-group-btn button.btn:visited
  background: #ffffff

.well.compare-quotes-wrapper button
  border-radius: 35px
  font-weight 600
  font-size: 16px
  padding: 8px 8px

.well.compare-quotes-wrapper .call-detail
  color: #ffffff
  margin-top: 15px
  text-align: center

.well.compare-quotes-wrapper .call-detail h4
  font-size: 20px
  font-weight 600
  margin-bottom: 0px
  margin-top: 5px

.well.compare-quotes-wrapper .call-detail h4 img
  margin-top: -3px

.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn)
  width: 100% !Important

.btn-group.bootstrap-select button
  background: #ffffff url("https://asset.healthinsurancecomparison.com.au/images/icons/cover-target.png") no-repeat center left 0.9rem
  border: none
  box-shadow: none
  border-radius: 4px
  display: block
  width: 100%
  height: 34px
  padding: 6px 12px 6px 28px
  color: #555

.bootstrap-select.btn-group .dropdown-toggle .filter-option
  font-size: 14px
  font-weight 600

.panel.popular-post
  margin-bottom: 12px

.panel.popular-post .panel-body .media .media-body h6
  color: #71be59
  font-size: 10px

.panel.popular-post .panel-body .media .media-body h5
  font-weight 600
  margin: 0px
  line-height: 17px

.panel.popular-post .panel-body .media .media-body a
  color: #343434
  text-decoration: none

.panel.popular-post .panel-body .media .media-body a:hover,
.panel.popular-post .panel-body .media .media-body a:focus
  color: #35899c
  text-decoration: none

.ct-wrapper
  display: block
  margin: 35px 0px

.ct-wrapper .panel-group .panel
  border: none
  border-bottom-left-radius: 3px
  border-bottom-right-radius: 3px
  overflow: hidden
  margin: 0 0 5px

.ct-wrapper .panel-group .panel .panel-heading
  color: #ffffff
  font-weight 600
  background-color: #354954
  border-color: #354954

.ct-wrapper .panel-group .panel .panel-heading a
  text-decoration: none
  font-size: 20px
  display: block

.ct-wrapper .panel-group .panel .panel-heading a:hover,
.ct-wrapper .panel-group .panel .panel-heading a:focus,
.ct-wrapper .panel-group .panel .panel-heading a:visited,
.ct-wrapper .panel-group .panel .panel-heading a:active
  color: #71be59

.ct-wrapper .panel-group .panel .panel-body
  background-color: #fafafa
  border-top: 0px
  border: 1px solid #ddd
  border-bottom-left-radius: 3px
  border-bottom-right-radius: 3px

.ct-wrapper .panel-group .panel .panel-body p
  color: #474747

.ct-wrapper .panel-group .panel .panel-body p strong
  color: #000000

.ct-wrapper .panel-group .panel .panel-body p:last-child
  margin: 0px

.ct-wrapper .panel-group .panel .panel-body ul
  padding: 0px
  margin: 0px

.ct-wrapper .panel-group .panel .panel-body ul li
  list-style-type: none
  margin: 10px 0px
  padding-left: 25px
  background: url("https://asset.healthinsurancecomparison.com.au/images/icons/checked.png") no-repeat top 0.5rem left

.article-content-wrapper .ct-wrapper .table-calculator-wrapper .panel
  border: none

.article-content-wrapper .ct-wrapper .table-calculator-wrapper .panel .panel-body.rebate-wrapper
  padding: 0px

.article-content-wrapper .ct-wrapper .table-calculator-wrapper .panel .panel-body:before
  background: none

.article-content-wrapper .ct-wrapper .table-calculator-wrapper .table-responsive
  margin-top: 30px

.article-content-wrapper .ct-wrapper .table-calculator-wrapper .panel .panel-body .rebate-result-wrapper p:last-child
  margin: 0px

.article-content h3
  font-weight 600

.ct-wrapper .row.insurance-companies-wrapper
  margin-top: 25px

.ct-wrapper .insurance-companies-wrapper .panel .panel-body
  min-height: 170px
  padding: 15px 25px

.ct-wrapper .insurance-companies-wrapper .panel .panel-body p
  margin-top: 8px
  margin-bottom: 0px
  font-size: 13px
  color: #474747
  line-height: 18px

.ct-wrapper .insurance-companies-wrapper .panel .panel-body img.img-abs
  position: absolute
  right: 15px
  top: 12px

.logo-company-wrapper
  display: block
  padding: 0px 5px 10px 5px
  text-align: center
  vertical-align: middle
  height: 51px

.about-content-wrapper .article-content .ct-wrapper .insurance-companies-wrapper a
 text-decoration: none

.ct-wrapper .insurance-companies-wrapper a .panel
  transition: all 0.4s ease
  margin-top: 0

.ct-wrapper .insurance-companies-wrapper a:hover .panel,
.ct-wrapper .insurance-companies-wrapper a:focus .panel
  border-color: #22859d
  text-decoration: none

.company-page .article-content-wrapper #quotes-form.well .media .media-body p a
  display: inline-block

@media (min-width: 320px)
  .ct-wrapper .panel-group .panel .panel-body
    padding: 15px

  .ct-wrapper .insurance-companies-wrapper .col-lg-4
    padding-right: 15px

@media (min-width: 480px)
  .ct-wrapper .panel-group .panel .panel-body
    padding: 15px

  .ct-wrapper .insurance-companies-wrapper .col-lg-4
    padding-right: 15px

@media (min-width: 768px)
  .ct-wrapper .panel-group .panel .panel-body
    padding: 25px

  .ct-wrapper .insurance-companies-wrapper .col-lg-4
    padding-right: 15px

@media (min-width: 992px)
  .ct-wrapper .panel-group .panel .panel-body
    padding: 30px

  .ct-wrapper .insurance-companies-wrapper .col-lg-4
    padding-right: 0px

@media (min-width: 1200px)
  .ct-wrapper .panel-group .panel .panel-body
    padding: 30px

  .ct-wrapper .insurance-companies-wrapper .col-lg-4
    padding-right: 0px



.ct-wrapper h4.panel-title
    margin: 0
    padding: 0


#Performances img
    max-width: 100%
    mix-blend-mode multiply


@media (min-width 1200px)
    .company_page .ct-wrapper
        padding-right: 30px


// custom css style
@media (max-width: 767px)
  .ct-wrapper .insurance-companies-wrapper .panel .panel-body
    min-height: 240px
  .logo-company-wrapper
    img
      max-width: 100%

@media (max-width: 320px)
  .ct-wrapper .insurance-companies-wrapper .panel .panel-body
    padding: 15px
  .ct-wrapper .insurance-companies-wrapper .panel .panel-body img.img-abs 
    right: 8px
    top: 8px